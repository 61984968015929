// import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';

const LASTSEE_KEY = 'lastseeItems';

@Injectable()
export class LastseeProvider {

  constructor(public storage: Storage) {
    console.log('Hello LastseeProvider Provider');
  }

  addToLastsee(product) {
    return this.getLastseeItems().then(result => {
      if (result) {
        if (!this.containsObject(product, result)) {
          result.push(product);
          return this.storage.set(LASTSEE_KEY, result);
        } else {
          let index = result.findIndex(x => x.product_id == product.product_id);
          let prevQuantity = parseInt(result[index].count);
          product.count = (prevQuantity + product.count);
          let currentPrice = (parseFloat(product.totalPrice) * product.count);
          product.totalPrice =currentPrice;
           result.splice(index, 1);
          result.push(product);
          return this.storage.set(LASTSEE_KEY, result);
        }

      } else {
        return this.storage.set(LASTSEE_KEY, [product]);
      }
    })
  }

  removeFromLastsee(product) {
    return this.getLastseeItems().then(result => {
      if (result) {
        var productIndex = result.indexOf(product);
        result.splice(productIndex, 1);
        return this.storage.set(LASTSEE_KEY, result);
      }
    })
  }

  removeAllLastseeItems() {
    return this.storage.remove(LASTSEE_KEY).then(res => {
      return res;
    });
  }


  containsObject(obj, list): boolean {
    if (!list.length) {
      return false;
    }

    if (obj == null) {
      return false;
    }
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].product_id == obj.product_id) {
        return true;
      }
    }
    return false;
  }



  getLastseeItems() {
    return this.storage.get(LASTSEE_KEY);
  }

}
