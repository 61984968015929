import { Component, OnInit, Injector } from '@angular/core';
import { PopoverController, NavParams, Events, Platform } from '@ionic/angular';
// import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ProductService } from '../../services/product.service';
import { CartProvider } from '../../providers/cart/cart';
import { BarcodeScanner, BarcodeScannerOptions, BarcodeScanResult } from '@ionic-native/barcode-scanner/ngx';
import { BasePage } from '../../pages/base-page/base-page';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends BasePage {
  page;
  options: BarcodeScannerOptions;
  scannedData:any={};
  item:any

  constructor(
    public events: Events,
    private navParams: NavParams,
    public scanner: BarcodeScanner,
    public api: ProductService,
    private plt: Platform,
    private cartService: CartProvider,
    injector: Injector,
    private popoverController: PopoverController) {
    super(injector);

  }

  ngOnInit() {
    //Get data from popover page
    this.page = this.navParams.get('data');
  }

  scan(){
    this.options=  { 
      prompt: 'Scan Barcode',
      showFlipCameraButton : true, // iOS and Android
      showTorchButton : true, // iOS and Android
      torchOn: true,
      resultDisplayDuration: 500,
    };
    this.popoverController.dismiss();
    this.scanner.scan(this.options).then((data) => {
      this.scannedData = data;
      // this.navigateTo(this.currentPath + '/' + data);
    //  let scanproduct =  this.getSingleProduct('?Code='+this.scannedData.text);
    //  if (scanproduct) {
    //   this.addToCart(this.item);
    // } else {
    //   this.scan();
    // }
      this.navigateTo('/1/home/items/?Code='+this.scannedData.text);

      console.log('scanned', this.scannedData.text);
    }, (err) => {
      console.log('Error : ', err);
    })
  }

  multiscan(){
    this.options=  { 
      prompt: 'Scan Barcode',
      showFlipCameraButton : true, // iOS and Android
      showTorchButton : true, // iOS and Android
      torchOn: true,
      resultDisplayDuration: 500,
    };
    this.scanner.scan(this.options).then((data) => {
      this.scannedData = data;
      // this.navigateTo(this.currentPath + '/' + data);
     let scanproduct =  this.getSingleProduct('?Code='+this.scannedData.text);

      // this.navigateTo(this.currentPath + '/items/?Code='+this.scannedData.text);
      console.log('scanned', this.scannedData.text);
    }, (err) => {
      console.log('Error : ', err);
    })
  }

  async getSingleProduct(item:any) {
    // const loading = await this.loadingController.create({
    //   message: 'Please wait...',
    // });
    // await loading.present();
    let code = item.substr(6, 7)
    let key = item.substr(13, 6)
    console.log(code)
    console.log(key)
    if (this.plt.is('cordova')) {
      this.api.getProductsByDetailNative(code, key)
      .then(data => {
        let parsed = JSON.parse(data.data);
        this.item = parsed.stArticle;
        // this.items = parsed.stRelated.arrArticleInList;
        console.log('item', data);
        // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
    } else {
    this.api.getProductsByDetail(code, key)
      .subscribe(res => {
        console.log('item', res);
        this.item = res['stArticle'];
          console.log('must be in the cart', this.item)
        // this.items = res['stRelated']['arrArticleInList'];
        // console.log('related', this.items)
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
    }
  }

  addToCart(item) {
    console.log(item);
    var productPrice = this.item.nQuantity * parseFloat(item.stPrice.curNetPriceInc);
    let cartProduct = {
      product_id: item.sArticleCode,
      sDescription1: item.sDescription1,
      sArticleBrand: item.sArticleBrand,
      sImageName: item.sImageName,
      sPromoImage: item.sPromoImage,
      count: this.item.nQuantity,
      iNbPoints: item.iNbPoints,
      totalPrice: productPrice
    };
    this.cartService.addToCart(cartProduct).then((val) => {

    });
}

  eventFromPopover() {
    this.events.publish('fromPopoverEvent');
    this.popoverController.dismiss();
  }

  close(){
    this.popoverController.dismiss();
  }

}