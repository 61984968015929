import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Item } from '../../services/item';
import { Router, NavigationExtras } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { AuthService } from '../../services/auth.service';
import {
  Platform
} from "@ionic/angular";
import { registerLocaleData } from '@angular/common';
import nl from '@angular/common/locales/nl';

@Component({
  selector: 'app-cart-ordertotal',
  templateUrl: './cart-ordertotal.component.html',
  styleUrls: ['./cart-ordertotal.component.scss'],
})
export class CartOrdertotalComponent implements OnInit, OnChanges {
	@Input() cartItems: Item[];

	totalAmount = 0;
	constructor(private plt: Platform,public router: Router,public api: ProductService,private authService: AuthService,) {}


	ngOnChanges(changes: SimpleChanges) {
		const dataChanges: SimpleChange = changes.cartItems;

		const cartItems: Item[] = dataChanges.currentValue;
		this.totalAmount = 0;
    cartItems.forEach((v, indx) => {
      this.totalAmount += parseFloat(v.totalPrice);
      console.log('ngchanges', this.totalAmount)
    });
	}

	ngOnInit() {    registerLocaleData( nl );}

	async goToCheckout() {

		try {
		  
		  // this.isSavingCart = true;
		  // await this.cart.save();
		  // this.isSavingCart = false;
	
		  this.router.navigateByUrl('/1/cart/checkout/thanks');
		  
		} catch (error) {
		  // this.isSavingCart = false;
		  // this.translate.get('ERROR_NETWORK').subscribe(str => this.showToast(str));
		}
	
	  }
	
	  goToSubCategoryPage() {
		this.router.navigateByUrl('/1/cart/checkout/thanks');
	  }
}