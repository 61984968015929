import { Injectable, ChangeDetectorRef, Injector } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Item } from '../services/item';
import { Observable, of, throwError } from 'rxjs';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { AuthService } from '.././services/auth.service';
import { BasePage } from '../pages/base-page/base-page';

const CART_KEY = 'cartItems';
const PBMCART_KEY = 'PBMcartItems';

@Injectable({
  providedIn: 'root'
})
export class CartService extends BasePage {
  currentprice: number = 0;
  navbarCartCount = 0;
  navbarPBMCartCount = 0;
  OrderNumber: any;
  order: any;
  orderchange: any;
  constructor(injector: Injector,private http: Http, private commonhttp: HttpClient, private nativeHttp: HTTP, private auth: AuthService, public storage: Storage) {
       super(injector);
    this.calculateLocalCartProdCounts();
    this.calculateLocalPBMCartProdCounts();

  }
  //   addToCart(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       if (!this.containsObject(product, result)) {
  //         result.push(product);
  //         return this.storage.set(CART_KEY, result);
  //       } else {
  //         let index = result.findIndex(x => x.product_id == product.product_id);
  //         let prevQuantity = parseInt(result[index].count);
  //         product.count = (prevQuantity + product.count);
  //         let currentPrice = (parseFloat(product.totalPrice));
  //         product.totalPrice =currentPrice;
  //         this.currentprice = currentPrice
  //          result.splice(index, 1);
  //         result.push(product);
  //         return this.storage.set(CART_KEY, result);
  //       }

  //     } else {
  //       return this.storage.set(CART_KEY, [product]);
  //     }
  //   })
  // }

  // Adding new Product to cart db if logged in else localStorage
  addToCart(data: any): void {
    let a: Item[];

    a = JSON.parse(localStorage.getItem('avct_item')) || [];

    a.push(data);
    // this.toastrService.wait('Adding Product to Cart', 'Product Adding to the cart');
    setTimeout(() => {
      localStorage.setItem('avct_item', JSON.stringify(a));
      this.calculateLocalCartProdCounts();
    }, 500);
  }

  // returning LocalCarts Product Count
  calculateLocalCartProdCounts() {
    this.navbarCartCount = this.getLocalCartProducts().length;
  }

  getLocalCartProducts(): Item[] {
    const products: Item[] = JSON.parse(localStorage.getItem('avct_item')) || [];

    return products;
  }

  removeLocalCartProduct(product: Item) {
    const products: Item[] = JSON.parse(localStorage.getItem('avct_item'));

    for (let i = 0; i < products.length; i++) {
      if (products[i].sArticleCode === product.product_id) {
        products.splice(i, 1);
        break;
      }
    }
    // ReAdding the products after remove
    localStorage.setItem('avct_item', JSON.stringify(products));

    this.calculateLocalCartProdCounts();
  }

  removeFromCart(product) {
    return this.getCartItems().then(result => {
      if (result) {
        var productIndex = result.indexOf(product);
        result.splice(productIndex, 1);
        return this.storage.set(CART_KEY, result);
      }
    })
  }

  // postOrder(): Observable<any> {
  //   let headers = new HttpHeaders().set('UserToken', this.auth.AuthToken);;
  //   headers.append('Access-Control-Allow-Origin' , '*');
  //   headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //   headers.append('Accept','application/json');
  //   headers.append('content-type','application/json');
  //   headers.append('content-type','application/json');
  //   console.log('UserToken', this.auth.AuthToken)
  //   let sale = this.http.post('/Order', {headers:headers});
  //   console.log('order', sale);
  //   return sale;
  // }

  postOrder() {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.AuthToken);
      console.log(this.auth.AuthToken)
      let url = 'https://demorest.msgsoftware.nl/Order';
      let user = this.http.post(url, '', options)
        .subscribe(data => {
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
          }
          else
            resolve(false);
        });
    });
  }

  async postOrderNative(): Promise<HTTPResponse> {

    let headers = {
      'Content-Type': 'application/json',
      "UserToken": `${this.auth.AuthToken}`
    };
    let sale = this.nativeHttp.post('https://demorest.msgsoftware.nl/Order', {}, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        // this.storeUserID(parsed.stAuthResp.iUserId);
        // this.storeClientID(parsed.stAuthResp.iClient);
        // this.storeUserType(parsed.stAuthResp.iUserType);
        // this.storePBM(parsed.stAuthResp.iPbmMode);
        // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        this.storeOrdernumber(parsed.sResponse);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        // this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  
      return sale
  }

  postOrderListName(name) {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.AuthToken);
      console.log(this.auth.AuthToken)
      let url = 'https://demorest.msgsoftware.nl/Orderlist?'+name;
      let user = this.http.post(url, name, options)
        .subscribe(data => {
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
          }
          else
            resolve(false);
        });
    });
  }

  async postOrderListNameNative(name): Promise<HTTPResponse> {

    let headers = {
      'Content-Type': 'application/json',
      "UserToken": `${this.auth.AuthToken}`
    };
    let sale = this.nativeHttp.post('https://testrestservicesraa.msgnl.com/Orderlist', name, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        // this.storeUserID(parsed.stAuthResp.iUserId);
        // this.storeClientID(parsed.stAuthResp.iClient);
        // this.storeUserType(parsed.stAuthResp.iUserType);
        // this.storePBM(parsed.stAuthResp.iPbmMode);
        // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        this.storeOrdernumber(parsed.sResponse);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        // this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  
      return sale
  }

  postOrderListItem(id, name) {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.AuthToken);
      console.log(this.auth.AuthToken)
      let url = 'https://demorest.msgsoftware.nl/Orderlist/'+id;
      let user = this.http.post(url, name, options)
        .subscribe(data => {
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
          }
          else
            resolve(false);
        });
    });
  }

  async postOrderListItemNative(id, name): Promise<HTTPResponse> {

    let headers = {
      'Content-Type': 'application/json',
      "UserToken": `${this.auth.AuthToken}`
    };
    let sale = this.nativeHttp.post('https://demorest.msgsoftware.nl/Orderlist/'+id, name, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        // this.storeUserID(parsed.stAuthResp.iUserId);
        // this.storeClientID(parsed.stAuthResp.iClient);
        // this.storeUserType(parsed.stAuthResp.iUserType);
        // this.storePBM(parsed.stAuthResp.iPbmMode);
        // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        this.storeOrdernumber(parsed.sResponse);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        // this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  
      return sale
  }


  postOrderNativeNumber(data) {
    let parsed = JSON.parse(data.data);
    this.storeOrdernumber(parsed.sResponse);
  }

  postPBMOrder() {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.PBMAuthToken);
      console.log(this.auth.AuthToken)
      let url = '/Order';
      let user = this.http.post(url, '', options)
        .subscribe(data => {
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
          }
          else
            resolve(false);
        });
    });
  }

  async postPBMOrderNative(): Promise<HTTPResponse> {

    let headers = {
      'Content-Type': 'application/json',
      "UserToken": `${this.auth.PBMAuthToken}`
    };
    let sale = this.nativeHttp.post('https://demorest.msgsoftware.nl/Order', {}, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        // this.storeUserID(parsed.stAuthResp.iUserId);
        // this.storeClientID(parsed.stAuthResp.iClient);
        // this.storeUserType(parsed.stAuthResp.iUserType);
        // this.storePBM(parsed.stAuthResp.iPbmMode);
        // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        this.storeOrdernumber(parsed.sResponse);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        // this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  
      return sale
  }

  postPBMOrderListName(name) {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.PBMAuthToken);
      console.log(this.auth.PBMAuthToken)
      let url = '/Orderlist?'+name;
      let user = this.http.post(url, name, options)
        .subscribe(data => {
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
          }
          else
            resolve(false);
        });
    });
  }

  async postPBMOrderListNameNative(name): Promise<HTTPResponse> {

    let headers = {
      'Content-Type': 'application/json',
      "UserToken": `${this.auth.PBMAuthToken}`
    };
    let sale = this.nativeHttp.post('https://demorest.msgsoftware.nl/Orderlist', name, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        // this.storeUserID(parsed.stAuthResp.iUserId);
        // this.storeClientID(parsed.stAuthResp.iClient);
        // this.storeUserType(parsed.stAuthResp.iUserType);
        // this.storePBM(parsed.stAuthResp.iPbmMode);
        // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        this.storeOrdernumber(parsed.sResponse);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        // this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  
      return sale
  }

  postPBMOrderListItem(id, name) {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.PBMAuthToken);
      console.log(this.auth.PBMAuthToken)
      let url = '/Orderlist/'+id;
      let user = this.http.post(url, name, options)
        .subscribe(data => {
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
          }
          else
            resolve(false);
        });
    });
  }

  async postPBMOrderListItemNative(id, name): Promise<HTTPResponse> {

    let headers = {
      'Content-Type': 'application/json',
      "UserToken": `${this.auth.PBMAuthToken}`
    };
    let sale = this.nativeHttp.post('https://demorest.msgsoftware.nl/Orderlist/'+id, name, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        // this.storeUserID(parsed.stAuthResp.iUserId);
        // this.storeClientID(parsed.stAuthResp.iClient);
        // this.storeUserType(parsed.stAuthResp.iUserType);
        // this.storePBM(parsed.stAuthResp.iPbmMode);
        // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        this.storeOrdernumber(parsed.sResponse);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        // this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  
      return sale
  }


  postPBMOrderNativeNumber(data) {
    let parsed = JSON.parse(data.data);
    this.storeOrdernumber(parsed.sResponse);
  }

  // getOrder(){
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin' , '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept','application/json');
  //     headers.append('content-type','application/json');
  //     headers.append('UserToken', this.auth.AuthToken);
  //     console.log(this.auth.AuthToken)
  //     let url = '/Order/'+this.OrderNumber; 
  //     console.log(url)
  //     let user = this.http.get(url,options)
  //     .subscribe( data => {
  //       console.log(data.json())
  //       this.order = data.json();
  //         if(data.json().sResponse){
  //             console.log('carserviceordernumber', data.json().sResponse)
  //             this.storeOrdernumber(data.json().sResponse);
  //         }
  //         else
  //             resolve(false);
  //     });
  //   });
  // }

  // async getOrderNative() {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${this.auth.AuthToken}`
  //   };
  //   this.nativeHttp.setDataSerializer("json");
  //   this.nativeHttp.get('https://demorest.msgsoftware.nl/Order/'+this.OrderNumber, {}, headers)
  //     .then((data) => {
  //       let parsed = JSON.parse(data.data);
  //       this.storeOrdernumber(parsed.sResponse);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  getOrder(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', this.auth.AuthToken);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('tokenproduct', this.auth.AuthToken)
    // headers.append('UserToken', this.auth.AuthToken);
    let items = this.commonhttp.get('/Order/'+this.OrderNumber, { headers: headers });
    console.log('order', items);
    return items;
  }

  getOrderNative(): Promise<HTTPResponse> {
    let headers = {
      "Content-Type": "application/json",
      "UserToken": `${this.auth.AuthToken}`
    };
    console.log(headers)
    this.nativeHttp.setDataSerializer("json");
    console.log(headers);
    let items = this.nativeHttp.get('https://demorest.msgsoftware.nl/Order/'+this.OrderNumber, {}, headers);
    console.log('order', items);
    console.log('this is native');
    return items;
  }

  getOrderLine(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', this.auth.AuthToken);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('tokenproduct', this.auth.AuthToken)
    // headers.append('UserToken', this.auth.AuthToken);
    let items = this.commonhttp.get('https://demorest.msgsoftware.nl/Orderline/'+this.OrderNumber, { headers: headers });
    console.log('orderlines', items);
    return items;
  }

  getOrderLineNative(): Promise<HTTPResponse> {
    let headers = {
      "Content-Type": "application/json",
      "UserToken": `${this.auth.AuthToken}`
    };
    console.log(headers)
    this.nativeHttp.setDataSerializer("json");
    console.log(headers);
    let items = this.nativeHttp.get('https://demorest.msgsoftware.nl/OrderLine/'+this.OrderNumber, {}, headers);
    console.log('orderlines', items);
    console.log('this is native');
    return items;
  }

  getPBMOrder(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken',  this.auth.PBMAuthToken);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('tokenproduct', this.auth.PBMAuthToken)
    // headers.append('UserToken', this.auth.AuthToken);
    let items = this.commonhttp.get('https://demorest.msgsoftware.nl/Order/'+this.OrderNumber, { headers: headers });
    console.log('order', items);
    return items;
  }

  getPBMOrderNative(): Promise<HTTPResponse> {
    let headers = {
      "Content-Type": "application/json",
      "UserToken": `${this.auth.PBMAuthToken}`
    };
    console.log(headers)
    this.nativeHttp.setDataSerializer("json");
    console.log(headers);
    let items = this.nativeHttp.get('https://demorest.msgsoftware.nl/Order/'+this.OrderNumber, {}, headers);
    console.log('order', items);
    console.log('this is native');
    return items;
  }

  getPBMOrderLine(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken',  this.auth.PBMAuthToken);
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('tokenproduct',  this.auth.PBMAuthToken)
    // headers.append('UserToken', this.auth.AuthToken);
    let items = this.commonhttp.get('https://demorest.msgsoftware.nl/Orderline/'+this.OrderNumber, { headers: headers });
    console.log('orderlines', items);
    return items;
  }

  getPBMOrderLineNative(): Promise<HTTPResponse> {
    let headers = {
      "Content-Type": "application/json",
      "UserToken": `${this.auth.PBMAuthToken}`
    };
    console.log(headers)
    this.nativeHttp.setDataSerializer("json");
    console.log(headers);
    let items = this.nativeHttp.get('https://demorest.msgsoftware.nl/OrderLine/'+this.OrderNumber, {}, headers);
    console.log('orderlines', items);
    console.log('this is native');
    return items;
  }

  putOrder(orderlines, stAddrDelivery) {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      console.log(this.currentPath+'/thanks/'+this.OrderNumber)
      let json = {
        "stOrder": {
          "bComplete": false,
          "bInform": false,
          "bUseNews": false,
          "bUrgentOrder": false,
          "iAccClient": 0,
          "sAccAdres": "",
          "sContactMail": "testmail@msgnl.com",
          "sContactTel": "0612345678",
          "sContactGsm": "",
          "sOrderCompTyp": "",
          "sOrderCorr": "DEMO",
          "sOrderKvkNr": "",
          "sOrderMemo": "mijn opmerking",
          "sOrderName": "DEMO MODEL WEBSHOP",
          "sOrderSexe": "1",
          "sOrderUser": "test",
          "sOrderValuta": "EUR",
          "sOrderVatNr": "",
          "sOrderWork": "",
          "sOrderYourRef": "mijn ref",
          "sOrderXML": "",
          "stAddrDelivery": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "INV",
            "sCity": "DEMOWOUD",
            "sCountry": "NEDERLAND",
            "sCountryCode": "NL",
            "sHouseNumber": "",
            "sSociete": "01",
            "sStreet1": "DEMOSTRAAT 11",
            "sStreet2": "",
            "sZipCode": "DEMO",
            "sLongitude": "",
            "sLatitude": ""
          },
          "stAddrInvoice": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "INV",
            "sCity": "DEMOWOUD",
            "sCountry": "NEDERLAND",
            "sCountryCode": "NL",
            "sHouseNumber": "",
            "sSociete": "01",
            "sStreet1": "DEMOSTRAAT 11",
            "sStreet2": "",
            "sZipCode": "DEMO",
            "sLongitude": "",
            "sLatitude": ""
          },
          "stAddrWork": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "WRK",
            "sCity": "",
            "sCountry": "",
            "sCountryCode": "",
            "sHouseNumber": "",
            "sSociete": "",
            "sStreet1": "",
            "sStreet2": "",
            "sZipCode": "",
            "sLongitude": "",
            "sLatitude": ""
          }
        },
        "stOrderFiles": {
          "arrArtURL": [

          ]
        },
        "stOrderLines": {
          "curTotExcl": 24.9,
          "curTotGross": 24.9,
          "curTotIncl": 30.13,
          "curTotVAT": 5.23,
          "iNbOfLines": 1,
          "iTotPoints": 0,
          "arrOrderLine": [
            orderlines
          ]
        },
        "stOrderPayment": {
          "sAccBic": "",
          "sAccCity": "",
          "sAccIban": "",
          "sAccName": "",
          "sAccNr": "",
          "sCardType": "",
          "sCardOwner": "",
          "sCardNr": "",
          "sCardSafety": "",
          "sCardMonth": "",
          "sCardYear": "",
          "sIdealStat": "",
          "sIdealParam": "",
          "sIdealError": "",
          "sIdealPayID": "",
          "sIdealPM": "",
          "sIdealLink": "https://webshop.vandijk.com/DIJK_WEB/nl/PaymentCompleteReturn.awp?OrderNr="+`${this.OrderNumber}`+"%26Project=DYK"
        },
        "stOrderState": {
          "bEMaintenance": false,
          "bKopAccJN": false,
          "bKopOCI": false,
          "dDeliveryDate": "0000-00-00",
          "dtOrderStamp": "0000-00-00T00:00:00.000",
          "iDeliveryType": 1,
          "iOrderLang": 3,
          "iOrderNumber": this.OrderNumber,
          "iOrderState": 7,
          "iOrderType": 100,
          "iOrderPayment": 21,
          "sOrderIPAddr": "::1",
          "sOrderValuta": "EUR"
        }
      }
      console.log(json);
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.AuthToken);
      console.log(this.auth.AuthToken)
      let url = '/Order/'+this.OrderNumber;
      console.log(url)
      console.log(this.orderchange)
      let user = this.http.put(url, json, options)
        .subscribe(data => {
          console.log(data.json())
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
            this.OrderNumber = data.json().sResponse
          }
          else
            resolve(false);
        });
    });
  }

  putOrderNative(orderlines, stAddrDelivery): Promise<HTTPResponse>  {

    // return new Promise((resolve, reject) => {
      let json = {
        "stOrder": {
          "bComplete": false,
          "bInform": false,
          "bUseNews": false,
          "bUrgentOrder": false,
          "iAccClient": 0,
          "sAccAdres": "",
          "sContactMail": "testmail@msgnl.com",
          "sContactTel": "0612345678",
          "sContactGsm": "",
          "sOrderCompTyp": "",
          "sOrderCorr": "DEMO",
          "sOrderKvkNr": "",
          "sOrderMemo": "mijn opmerking",
          "sOrderName": "DEMO MODEL WEBSHOP",
          "sOrderSexe": "1",
          "sOrderUser": "test",
          "sOrderValuta": "EUR",
          "sOrderVatNr": "",
          "sOrderWork": "",
          "sOrderYourRef": "mijn ref",
          "sOrderXML": "",
          "stAddrDelivery": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "INV",
            "sCity": "DEMOWOUD",
            "sCountry": "NEDERLAND",
            "sCountryCode": "NL",
            "sHouseNumber": "",
            "sSociete": "01",
            "sStreet1": "DEMOSTRAAT 11",
            "sStreet2": "",
            "sZipCode": "DEMO",
            "sLongitude": "",
            "sLatitude": ""
          },
          "stAddrInvoice": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "INV",
            "sCity": "DEMOWOUD",
            "sCountry": "NEDERLAND",
            "sCountryCode": "NL",
            "sHouseNumber": "",
            "sSociete": "01",
            "sStreet1": "DEMOSTRAAT 11",
            "sStreet2": "",
            "sZipCode": "DEMO",
            "sLongitude": "",
            "sLatitude": ""
          },
          "stAddrWork": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "WRK",
            "sCity": "",
            "sCountry": "",
            "sCountryCode": "",
            "sHouseNumber": "",
            "sSociete": "",
            "sStreet1": "",
            "sStreet2": "",
            "sZipCode": "",
            "sLongitude": "",
            "sLatitude": ""
          }
        },
        "stOrderFiles": {
          "arrArtURL": [

          ]
        },
        "stOrderLines": {
          "curTotExcl": 24.9,
          "curTotGross": 24.9,
          "curTotIncl": 30.13,
          "curTotVAT": 5.23,
          "iNbOfLines": 1,
          "iTotPoints": 0,
          "arrOrderLine": [
            orderlines
          ]
        },
        "stOrderPayment": {
          "sAccBic": "",
          "sAccCity": "",
          "sAccIban": "",
          "sAccName": "",
          "sAccNr": "",
          "sCardType": "",
          "sCardOwner": "",
          "sCardNr": "",
          "sCardSafety": "",
          "sCardMonth": "",
          "sCardYear": "",
          "sIdealStat": "",
          "sIdealParam": "",
          "sIdealError": "",
          "sIdealPayID": "",
          "sIdealPM": "",
          "sIdealLink": "https://webshop.vandijk.com/DIJK_WEB/nl/PaymentCompleteReturn.awp?OrderNr="+`${this.OrderNumber}`+"%26Project=DYK"
        },
        "stOrderState": {
          "bEMaintenance": false,
          "bKopAccJN": false,
          "bKopOCI": false,
          "dDeliveryDate": "0000-00-00",
          "dtOrderStamp": "0000-00-00T00:00:00.000",
          "iDeliveryType": 1,
          "iOrderLang": 3,
          "iOrderNumber": this.OrderNumber,
          "iOrderState": 7,
          "iOrderType": 100,
          "iOrderPayment": 21,
          "sOrderIPAddr": "::1",
          "sOrderValuta": "EUR"
        }
      }
      console.log(json);
      let headers = {
        'Content-Type': 'application/json',
        "UserToken": `${this.auth.AuthToken}`
      };

      console.log(this.auth.AuthToken)
      // this.nativeHttp.put('https://demorest.msgsoftware.nl/Order/'+this.OrderNumber, json, headers)
      //   .then(
      //     res => resolve(res),
      //     error => reject(<any>error));

          let sale = this.nativeHttp.put('https://demorest.msgsoftware.nl/Order/'+this.OrderNumber, json, headers)
          .then(
            res =>  {let parsed = JSON.parse(res.data);
              console.log(parsed);
              // this.userFullname(parsed.stAuthResp.sFullName);
            },
            error => error);
        
            return sale
    // });

  }


  putPBMOrder(orderlines, stAddrDelivery) {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      console.log(this.currentPath+'/thanks/'+this.OrderNumber)
      let json = {
        "stOrder": {
          "bComplete": false,
          "bInform": false,
          "bUseNews": false,
          "bUrgentOrder": false,
          "iAccClient": 0,
          "sAccAdres": "",
          "sContactMail": "testmail@msgnl.com",
          "sContactTel": "0612345678",
          "sContactGsm": "",
          "sOrderCompTyp": "",
          "sOrderCorr": "DEMO",
          "sOrderKvkNr": "",
          "sOrderMemo": "mijn opmerking",
          "sOrderName": "DEMO MODEL WEBSHOP",
          "sOrderSexe": "1",
          "sOrderUser": "test",
          "sOrderValuta": "EUR",
          "sOrderVatNr": "",
          "sOrderWork": "",
          "sOrderYourRef": "mijn ref",
          "sOrderXML": "",
          "stAddrDelivery": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "INV",
            "sCity": "DEMOWOUD",
            "sCountry": "NEDERLAND",
            "sCountryCode": "NL",
            "sHouseNumber": "",
            "sSociete": "01",
            "sStreet1": "DEMOSTRAAT 11",
            "sStreet2": "",
            "sZipCode": "DEMO",
            "sLongitude": "",
            "sLatitude": ""
          },
          "stAddrInvoice": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "INV",
            "sCity": "DEMOWOUD",
            "sCountry": "NEDERLAND",
            "sCountryCode": "NL",
            "sHouseNumber": "",
            "sSociete": "01",
            "sStreet1": "DEMOSTRAAT 11",
            "sStreet2": "",
            "sZipCode": "DEMO",
            "sLongitude": "",
            "sLatitude": ""
          },
          "stAddrWork": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "WRK",
            "sCity": "",
            "sCountry": "",
            "sCountryCode": "",
            "sHouseNumber": "",
            "sSociete": "",
            "sStreet1": "",
            "sStreet2": "",
            "sZipCode": "",
            "sLongitude": "",
            "sLatitude": ""
          }
        },
        "stOrderFiles": {
          "arrArtURL": [

          ]
        },
        "stOrderLines": {
          "curTotExcl": 24.9,
          "curTotGross": 24.9,
          "curTotIncl": 30.13,
          "curTotVAT": 5.23,
          "iNbOfLines": 1,
          "iTotPoints": 0,
          "arrOrderLine": [
            orderlines
          ]
        },
        "stOrderPayment": {
          "sAccBic": "",
          "sAccCity": "",
          "sAccIban": "",
          "sAccName": "",
          "sAccNr": "",
          "sCardType": "",
          "sCardOwner": "",
          "sCardNr": "",
          "sCardSafety": "",
          "sCardMonth": "",
          "sCardYear": "",
          "sIdealStat": "",
          "sIdealParam": "",
          "sIdealError": "",
          "sIdealPayID": "",
          "sIdealPM": "",
          "sIdealLink": "https://webshop.vandijk.com/DIJK_WEB/nl/PaymentCompleteReturn.awp?OrderNr="+`${this.OrderNumber}`+"%26Project=DYK"
        },
        "stOrderState": {
          "bEMaintenance": false,
          "bKopAccJN": false,
          "bKopOCI": false,
          "dDeliveryDate": "0000-00-00",
          "dtOrderStamp": "0000-00-00T00:00:00.000",
          "iDeliveryType": 1,
          "iOrderLang": 3,
          "iOrderNumber": this.OrderNumber,
          "iOrderState": 7,
          "iOrderType": 100,
          "iOrderPayment": 21,
          "sOrderIPAddr": "::1",
          "sOrderValuta": "EUR"
        }
      }
      console.log(json);
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.PBMAuthToken);
      console.log(this.auth.PBMAuthToken)
      let url = 'https://demorest.msgsoftware.nl/Order/'+this.OrderNumber;
      console.log(url)
      console.log(this.orderchange)
      let user = this.http.put(url, json, options)
        .subscribe(data => {
          console.log(data.json())
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
            this.OrderNumber = data.json().sResponse
          }
          else
            resolve(false);
        });
    });
  }

  putPBMOrderNative(orderlines, stAddrDelivery): Promise<HTTPResponse>  {

    // return new Promise((resolve, reject) => {
      let json = {
        "stOrder": {
          "bComplete": false,
          "bInform": false,
          "bUseNews": false,
          "bUrgentOrder": false,
          "iAccClient": 0,
          "sAccAdres": "",
          "sContactMail": "testmail@msgnl.com",
          "sContactTel": "0612345678",
          "sContactGsm": "",
          "sOrderCompTyp": "",
          "sOrderCorr": "DEMO",
          "sOrderKvkNr": "",
          "sOrderMemo": "mijn opmerking",
          "sOrderName": "DEMO MODEL WEBSHOP",
          "sOrderSexe": "1",
          "sOrderUser": "test",
          "sOrderValuta": "EUR",
          "sOrderVatNr": "",
          "sOrderWork": "",
          "sOrderYourRef": "mijn ref",
          "sOrderXML": "",
          "stAddrDelivery": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "INV",
            "sCity": "DEMOWOUD",
            "sCountry": "NEDERLAND",
            "sCountryCode": "NL",
            "sHouseNumber": "",
            "sSociete": "01",
            "sStreet1": "DEMOSTRAAT 11",
            "sStreet2": "",
            "sZipCode": "DEMO",
            "sLongitude": "",
            "sLatitude": ""
          },
          "stAddrInvoice": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "INV",
            "sCity": "DEMOWOUD",
            "sCountry": "NEDERLAND",
            "sCountryCode": "NL",
            "sHouseNumber": "",
            "sSociete": "01",
            "sStreet1": "DEMOSTRAAT 11",
            "sStreet2": "",
            "sZipCode": "DEMO",
            "sLongitude": "",
            "sLatitude": ""
          },
          "stAddrWork": {
            "iAddressNr": 0,
            "sAddressName": "",
            "sAddressType": "WRK",
            "sCity": "",
            "sCountry": "",
            "sCountryCode": "",
            "sHouseNumber": "",
            "sSociete": "",
            "sStreet1": "",
            "sStreet2": "",
            "sZipCode": "",
            "sLongitude": "",
            "sLatitude": ""
          }
        },
        "stOrderFiles": {
          "arrArtURL": [

          ]
        },
        "stOrderLines": {
          "curTotExcl": 24.9,
          "curTotGross": 24.9,
          "curTotIncl": 30.13,
          "curTotVAT": 5.23,
          "iNbOfLines": 1,
          "iTotPoints": 0,
          "arrOrderLine": [
            orderlines
          ]
        },
        "stOrderPayment": {
          "sAccBic": "",
          "sAccCity": "",
          "sAccIban": "",
          "sAccName": "",
          "sAccNr": "",
          "sCardType": "",
          "sCardOwner": "",
          "sCardNr": "",
          "sCardSafety": "",
          "sCardMonth": "",
          "sCardYear": "",
          "sIdealStat": "",
          "sIdealParam": "",
          "sIdealError": "",
          "sIdealPayID": "",
          "sIdealPM": "",
          "sIdealLink": "https://webshop.vandijk.com/DIJK_WEB/nl/PaymentCompleteReturn.awp?OrderNr="+`${this.OrderNumber}`+"%26Project=DYK"
        },
        "stOrderState": {
          "bEMaintenance": false,
          "bKopAccJN": false,
          "bKopOCI": false,
          "dDeliveryDate": "0000-00-00",
          "dtOrderStamp": "0000-00-00T00:00:00.000",
          "iDeliveryType": 1,
          "iOrderLang": 3,
          "iOrderNumber": this.OrderNumber,
          "iOrderState": 7,
          "iOrderType": 100,
          "iOrderPayment": 21,
          "sOrderIPAddr": "::1",
          "sOrderValuta": "EUR"
        }
      }
      console.log(json);
      let headers = {
        'Content-Type': 'application/json',
        "UserToken": `${this.auth.PBMAuthToken}`
      };

      console.log(this.auth.PBMAuthToken)
      // this.nativeHttp.put('https://demorest.msgsoftware.nl/Order/'+this.OrderNumber, json, headers)
      //   .then(
      //     res => resolve(res),
      //     error => reject(<any>error));

          let sale = this.nativeHttp.put('https://demorest.msgsoftware.nl/Order/'+this.OrderNumber, json, headers)
          .then(
            res =>  {let parsed = JSON.parse(res.data);
              console.log(parsed);
              // this.userFullname(parsed.stAuthResp.sFullName);
            },
            error => error);
        
            return sale
    // });

  }

  storeOrdernumber(OrderNumber) {
    window.localStorage.setItem('OrderNumber', OrderNumber);
    this.storage.set('OrderNumber', OrderNumber);
    this.useOrdernumber(OrderNumber);
  }

  useOrdernumber(token) {
    this.OrderNumber = token;
  }


  postOrderLine(item: any) {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.AuthToken);
      console.log(this.auth.AuthToken)
      console.log('test', item);
      let url = 'https://demorest.msgsoftware.nl/OrderLine/' + this.OrderNumber;
      let user = this.http.post(url, JSON.stringify(item), options)
        .subscribe(data => {
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
          }
          else
            resolve(false);
        });
    });
  }

    postPBMOrderLine(item: any) {
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept', 'application/json');
      headers.append('content-type', 'application/json');
      headers.append('UserToken', this.auth.PBMAuthToken);
      console.log(this.auth.AuthToken)
      console.log('test', item);
      let url = 'https://demorest.msgsoftware.nl/OrderLine/' + this.OrderNumber;
      let user = this.http.post(url, JSON.stringify(item), options)
        .subscribe(data => {
          if (data.json().sResponse) {
            console.log('carserviceordernumber', data.json().sResponse)
            this.storeOrdernumber(data.json().sResponse);
          }
          else
            resolve(false);
        });
    });
  }

  // postOrderLineNative(item) {
  //   return new Promise((resolve, reject) => {
  //     let headers = {
  //       'Content-Type': 'application/json',
  //       "UserToken": `${this.auth.AuthToken}`
  //     };
  //     this.nativeHttp.post('https://demorest.msgsoftware.nl/OrderLine/'+this.OrderNumber, item, headers)
  //       .then(
  //         res => resolve(res),
  //         error => reject(<any>error));
  //   });
  // }

  async postOrderLineNative(item): Promise<HTTPResponse> {
    // let loading =  this.loadingCtrl.create();
    let headers = {
      'Content-Type': 'application/json',
      "UserToken": `${this.auth.AuthToken}`
    };
    let sale = this.nativeHttp.post('https://demorest.msgsoftware.nl/OrderLine/'+this.OrderNumber, item, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        // this.storeUserID(parsed.stAuthResp.iUserId);
        // this.storeClientID(parsed.stAuthResp.iClient);
        // this.storeUserType(parsed.stAuthResp.iUserType);
        // this.storePBM(parsed.stAuthResp.iPbmMode);
        // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        // console.log(parsed.stAuthResp.iPbmMode);
        console.log('carserviceordernumber', parsed.sResponse)
        this.storeOrdernumber(parsed.sResponse);
        console.log(parsed);
        // this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  
      return sale
      
  }

  async postPBmOrderLineNative(item): Promise<HTTPResponse> {
    // let loading =  this.loadingCtrl.create();
    let headers = {
      'Content-Type': 'application/json',
      "UserToken": `${this.auth.PBMAuthToken}`
    };
    let sale = this.nativeHttp.post('https://demorest.msgsoftware.nl/OrderLine/'+this.OrderNumber, item, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        // this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        // this.storeUserID(parsed.stAuthResp.iUserId);
        // this.storeClientID(parsed.stAuthResp.iClient);
        // this.storeUserType(parsed.stAuthResp.iUserType);
        // this.storePBM(parsed.stAuthResp.iPbmMode);
        // this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        // console.log(parsed.stAuthResp.iPbmMode);
        console.log('carserviceordernumber', parsed.sResponse)
        this.storeOrdernumber(parsed.sResponse);
        console.log(parsed);
        // this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  
      return sale
      
  }


  // removeAllCartItems(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       var productIndex = result.indexOf(product);
  //       result.splice(productIndex, 1);
  //       return this.storage.remove(CART_KEY);
  //     }
  //   })
  // }


  // containsObject(obj, list): boolean {
  //   if (!list.length) {
  //     return false;
  //   }

  //   if (obj == null) {
  //     return false;
  //   }
  //   var i;
  //   for (i = 0; i < list.length; i++) {
  //     if (list[i].product_id == obj.product_id) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }



  getCartItems() {
    return this.storage.get(CART_KEY);
  }

  // PBMaddToCart(product) {
  //   return this.getPBMCartItems().then(result => {
  //     if (result) {
  //       if (!this.containsObject(product, result)) {
  //         result.push(product);
  //         return this.storage.set(PBMCART_KEY, result);
  //       } else {
  //         let index = result.findIndex(x => x.product_id == product.product_id);
  //         let prevQuantity = parseInt(result[index].count);
  //         product.count = (prevQuantity + product.count);
  //         let currentPrice = (parseInt(product.iNbPoints) * product.count);
  //         product.iNbPoints =currentPrice;
  //          result.splice(index, 1);
  //         result.push(product);
  //         return this.storage.set(PBMCART_KEY, result);
  //       }

  //     } else {
  //       return this.storage.set(PBMCART_KEY, [product]);
  //     }
  //   })
  // }

  PBMaddToCart(data: any): void {
    let a: Item[];

    a = JSON.parse(localStorage.getItem('pbm-cart')) || [];

    a.push(data);
    // this.toastrService.wait('Adding Product to Cart', 'Product Adding to the cart');
    setTimeout(() => {
      localStorage.setItem('pbm-cart', JSON.stringify(a));
      this.calculateLocalPBMCartProdCounts();
    }, 500);
  }

  // returning LocalCarts Product Count
  calculateLocalPBMCartProdCounts() {
    this.navbarPBMCartCount = this.getLocalPBMCartProducts().length;
  }

  getLocalPBMCartProducts(): Item[] {
    const products: Item[] = JSON.parse(localStorage.getItem('pbm-cart')) || [];

    return products;
  }

  removeLocalPBMCartProduct(product: Item) {
    const products: Item[] = JSON.parse(localStorage.getItem('pbm-cart'));

    for (let i = 0; i < products.length; i++) {
      if (products[i].sArticleCode === product.product_id) {
        products.splice(i, 1);
        break;
      }
    }
    // ReAdding the products after remove
    localStorage.setItem('pbm-cart', JSON.stringify(products));

    this.calculateLocalPBMCartProdCounts();
  }

  // PBMremoveFromCart(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       var productIndex = result.indexOf(product);
  //       result.splice(productIndex, 1);
  //       return this.storage.set(PBMCART_KEY, result);
  //     }
  //   })
  // }

  // PBMremoveAllCartItems(product) {
  //   return this.getCartItems().then(result => {
  //     if (result) {
  //       var productIndex = result.indexOf(product);
  //       result.splice(productIndex, 1);
  //       return this.storage.remove(PBMCART_KEY);
  //     }
  //   })
  // }


  // PBMcontainsObject(obj, list): boolean {
  //   if (!list.length) {
  //     return false;
  //   }

  //   if (obj == null) {
  //     return false;
  //   }
  //   var i;
  //   for (i = 0; i < list.length; i++) {
  //     if (list[i].product_id == obj.product_sArticleCode) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }


  getPBMCartItems() {
    return this.storage.get(PBMCART_KEY);
  }
}
