import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Item } from '../../services/item';
import { Router, NavigationExtras } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { AuthService } from '../../services/auth.service';
import {
  Platform
} from "@ionic/angular";

@Component({
  selector: 'app-pbmcart-total',
  templateUrl: './pbmcart-total.component.html',
  styleUrls: ['./pbmcart-total.component.scss'],
})
export class PbmcartTotalComponent implements OnInit {
	@Input() PBMcartItems: Item[];

  PBMtotalAmount = 0;
  PBMtotalAmount2 = 0;
  points: any;
	constructor(private plt: Platform,public router: Router,public api: ProductService,private authService: AuthService,) {}

	ngOnChanges(changes: SimpleChanges) {
		const dataChanges: SimpleChange = changes.PBMcartItems;

		const PBMcartItems: Item[] = dataChanges.currentValue;
    this.PBMtotalAmount = 0;
    this.PBMtotalAmount2 = 0;
    PBMcartItems.forEach((v, indx) => {
      this.PBMtotalAmount += parseInt(v.iNbPoints);
      console.log('ngchanges', this.PBMtotalAmount)
      let points = this.points[0].iPointSaldo -= this.PBMtotalAmount;
      this.PBMtotalAmount2 = points
    });

	}

  ngOnInit() {
    this.getPBMPoints(this.authService.iUserId);
    let points = this.points[0].iPointSaldo -= this.PBMtotalAmount;
    console.log(points)
    this.PBMtotalAmount2 = points
  }
  
  async goToCheckout() {

    try {
      
      // this.isSavingCart = true;
      // await this.cart.save();
      // this.isSavingCart = false;

      this.router.navigateByUrl('/1/cart/checkout');
      
    } catch (error) {
      // this.isSavingCart = false;
      // this.translate.get('ERROR_NETWORK').subscribe(str => this.showToast(str));
    }

  }

  async getPBMPoints(orderlist) {
    // const loading = await this.loadingController.create({
    //   message: 'Please wait...',
    // });
    // await loading.present();
    if (this.plt.is('cordova')) {
      this.api.getPBMPointsnative(orderlist)
      .then(data => {
        let parsed = JSON.parse(data.data);
        this.points = parsed.stPointList.arrPoints;
        console.log('punten', data);
        // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();

      }, err => {
        console.log(err);
        // loading.dismiss();

      });
    } else {
      this.api.getPBMPoints(orderlist)
      .subscribe(res => {
        console.log('punten', res);
        this.points = res['stPointList']['arrPoints'];
 
        // loading.dismiss();
      }, err => {
        console.log(err);
  
        // loading.dismiss();
      });
    }
  }

}
