import { Component, ViewChild, OnInit, Injector } from '@angular/core';
import { NavController ,Platform, Events, ModalController } from '@ionic/angular';
import { ProductsProvider } from '../../providers/products/products';
import { CategoryProvider } from '../../providers/category/category';
import { Observable } from 'rxjs/Observable';
import { ProductService } from '../../services/product.service';
import { BasePage } from '../base-page/base-page';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'page-modal',
  templateUrl: 'modal.page.html',
  styleUrls: ['modal.page.scss'],
})
export class ModalPage extends BasePage {
  // @ViewChild(Nav) nav: Nav;
  countries:any[];
  productRows:any;
  filterdata:any; 
  item:any;
  Object = Object;
  category: Observable<any>;
  products: Observable<any>;
  // filters: Observable<any>;
  filterows: Observable<any>;
  objectKeys: any = Object.keys;
  groups:Observable<any>;
  show = 1;
  iPropGrpID: any;
  filters;
  filtercategory;
  msg:any;
  customActionSheetOptions: any = {
    header: '#005FA9',
    subHeader: '#005FA9'
  };
  customAlertOptions: any = {
    header: 'Pizza Toppings',
    subHeader: 'Select your toppings',
    message: '$1.00 per topping',
    translucent: true
  };
  customPopoverOptions: any = {
    header: 'Hair Color',
    subHeader: 'Select your hair color',
    message: 'Only select your dominant hair color'
  };
  nCnt: number = 0;
  constructor(injector: Injector,public storage: Storage, private plt: Platform, private api2:ProductService, private modal:ModalController, private productService:ProductsProvider, public navCtrl: NavController, private categoryService:CategoryProvider, public events:Events) {
    // this.getCountries();
    // this.loadFilters();
    super(injector);
  }
  ngOnInit(){
    console.log('test', `${this.filters}`)
  }
  clickMe(event:Event) :void {
    this.nCnt = this.nCnt + 1;
    this.msg = this.nCnt;
    console.log('msg', this.msg)
}
  compareWithFn = (o1, o2) => {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  };

  ionViewDidEnter(category: any){
    console.log("ionViewDidEnter homepage");
    this.products = this.productService.products;
    this.loadFilters();
    this.loadProductsfilters(this.filters)
    // console.log(this.navParams.get('category').iGroupId);
  }
  initializeItems(): void {
    this.filters; 
    this.filtercategory;
    this.filterows;
    this.products = this.productService.products;
    this.countries = this.categoryService.countries;
  }
  closeModal(){
    // this.viewCtrl.dismiss();
    this.modal.dismiss();

  }

  trackById(index, item) {
    return item.iPropGrpID;
  }

  objectKey(obj) {
    return Object.keys(obj);
  }
  formatedCerts() {
    return this.filters.reduce((prev, now) => {
      if (!prev[now.sPropDesc]) {
        prev[now.sPropDesc] = [];
      }

      prev[now.sPropDesc].push(now);
      return prev;
    }, {});

  /*
     Now your data : { "1050 AJ": [ .... ], "X332.0 AC": [...], ... }
  */

}
  selectRad(data){
    console.log("Radio data",data);
    this.filters = data;
 }

 onSubCategoryTouched(subcategory) {
  let id = subcategory['iPropGrpID'];
  console.log(id)
  this.modal.dismiss(id);
  }

  loadProducts() {
    this.productService.getProducts();
    this.products = this.productService.products;
  }

  checkIfExist(iPropGrpID: number) {
    return this.filters.find( item => item['iPropGrpID'] === iPropGrpID ) 
  }

  loadFilters(){
    this.filters;
    console.log(this.filters);
    console.log(this.filterdata = {})
  }

  dismiss() {
    console.log("dismissing filtermodal with data",this.filterdata);
    this.modal.dismiss(this.filterdata);
  }

  async loadProductsfilters(item:any) {
    // const loading = await this.loadingController.create({
    //   message: 'Please wait...',
    // });
    // await loading.present();
    if (this.plt.is('cordova')) {
      this.api2.getProductsByNative(item)
      .then(data => {
        let parsed = JSON.parse(data.data);
        // this.items = parsed.stArticles.arrArticleInList;
        let filters = parsed.stPropFilter.arrProp
        this.filtercategory = filters.filter(i => i.sPropUnit === "CAT");
        this.filters = filters.filter(i => i.sPropUnit !== "CAT");
        console.log('categories', data);
        // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
    } else {
    this.api2.getProductsByCategory(item)
      .subscribe(res => {
        console.log('products', res);
        // this.items = res['stArticles']['arrArticleInList'];
        let filters = res['stPropFilter']['arrProp'];
        this.filtercategory = filters.filter(i => i.sPropUnit === "CAT");
        this.filters = filters.filter(i => i.sPropUnit !== "CAT" && i.iSequence === i.iSequence );
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
    }
  }


}

