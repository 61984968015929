import { CacheService } from 'ionic-cache';
import { Injectable } from '@angular/core';
import { Platform, Events, ToastController, LoadingController } from '@ionic/angular';
// import firebase from 'firebase';
import { Storage } from '@ionic/storage';
import { Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NetworkService, ConnectionStatus } from '../../services/network.service';
import { finalize } from 'rxjs/operators';
import { HTTP } from '@ionic-native/http/ngx';
import 'rxjs/add/operator/map';
import { from } from 'rxjs';
import { tap, map, catchError } from "rxjs/operators";
const categories_KEY = 'categories';
const subcategories_KEY = 'subcategories'

@Injectable()
export class CategoryProvider {

  // categoriesRef = firebase.database().ref("searchlevelinitial");
  // countryRef = firebase.database().ref("countries");
  // grapeRef = firebase.database().ref("grapevariety");
  // price1Ref = firebase.database().ref("price1");
  categories: any;
  data: [];
  subcategories: Observable<any>;
  categoriesKey = 'my-categories-group';
  subcategoriesKey = 'my-subcategories-group';
  countries: Array<any> = [];
  price1: Array<any> = [];
  grapevariety: Array<any> = [];
  constructor(private networkService: NetworkService, private plt: Platform, private nativeHttp: HTTP, public loadingCtrl: LoadingController, private toastCtrl:ToastController, private http: HttpClient, private cache: CacheService,public events: Events, public storage: Storage) {

  }

  
  // ionViewDidLoad() {
  //   this.dataFinder.getJSONDataAsync("./assets/data/data.json").then(data => {
  //     this.SetQueryOptionsData(data);
  //   });
  // }
 
  // /* Sets data with returned JSON array */
  // SetQueryOptionsData(data : any) {
  //   this.articleinitial = data.articleinitial;
  // }

    getCategories(): Observable<any[]> {
    // let loading = await this.loadingCtrl.create();
    // await loading.present();
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      // Return the cached data from Storage
      return from(this.getLocalData('categories'));
    } else {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin' , '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        headers.append('Accept','application/json');
        headers.append('content-type','application/json');
        let url = '/Category';
        let req = this.http.get(url, {headers:headers}).pipe(
          finalize(() => console.log('oke'))
        )
        .subscribe(data => {
          this.categories = data['stNavMenu']['arrMenuGroup'];
          console.log(data['stNavMenu']['arrMenuGroup']);
          console.log(this.categories);
          let ttl = 10;
          // Load with Group key and custom TTL
        this.categories = this.cache.loadFromObservable(url, req, this.categoriesKey, ttl);
      
          // Or just load without additional settings
        this.categories =  this.cache.loadFromObservable(url, req, this.categoriesKey);
        this.setLocalData('categories', data); 
        return this.categories
        }, err => {
          console.log('JS Call error: ', err);
        })
    }
  }

  // getUsers(forceRefresh: boolean = false): Observable<any[]> {
  //   if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline || !forceRefresh) {
  //     // Return the cached data from Storage
  //     return from(this.getLocalData('users'));
  //   } else {
  //     // Just to get some "random" data
  //     let page = Math.floor(Math.random() * Math.floor(6));
      
  //     // Return real API data and store it locally
  //     return this.http.get(`/Category`).pipe(
  //       map(res => res['data']),
  //       tap(res => {
  //         this.setLocalData('users', res);
  //       })
  //     )
  //   }
  // }
 
    getDataNativeHttp() {
    // let loading = await this.loadingCtrl.create();
    // await loading.present();
    let headers = {
      'Content-Type': 'application/json'
    };
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      // Return the cached data from Storage
      return from(this.getLocalData('categories'));
    } else {
    let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Category', {}, headers)
    console.log('this is native');
    from(nativeCall).pipe(
      finalize(() => console.log('oke'))
    )
    .subscribe(data => {

      let parsed = JSON.parse(data.data);
      this.categories = parsed.stNavMenu.arrMenuGroup;
      console.log(this.categories);
      console.log(data);
      this.setLocalData('categories', data); 
    }, err => {
      console.log('JS Call error: ', err);
    });
    }
  }
 
  getDataEverywhere() {
    if (this.plt.is('cordova')) {
      this.getDataNativeHttp();
    } else {
      this.getCategories();
    }
  }

  getSubCategories(category) {
    // let loading = await this.loadingCtrl.create();
    // await loading.present();
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept','application/json');
    headers.append('content-type','application/json');
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      // Return the cached data from Storage
      return from(this.getLocalData('subcategories'));
    } else {
    let url = '/Category?ParentId='+category;
    let req = this.http.get(url, {headers:headers}).pipe(
      finalize(() => console.log('oke'))
    )
    .subscribe(data => {
      this.subcategories = data['stNavMenu']['arrMenuGroup'];
      console.log(data['stNavMenu']['arrMenuGroup']);
      console.log(this.subcategories);
    //   let ttl = 10;
    //   // Load with Group key and custom TTL
    // this.subcategories = this.cache.loadFromObservable(url, req, this.subcategoriesKey, ttl);
  
    //   // Or just load without additional settings
    this.setLocalData('subcategories', data); 
    // this.subcategories =  this.cache.loadFromObservable(url, req, this.subcategoriesKey);
      return this.subcategories
    }, err => {
      console.log('JS Call error: ', err);
    });
  }
//       let ttl = 10;
//       console.log(url);
//       console.log(req);
//     // Specify custom TTL if you want
  
  
//     if (refresher) {
//       // Reload data even if it is cached
//       let delayType = 'all';
//       this.categories = this.cache.loadFromDelayedObservable(url, req, this.categoriesKey, ttl, delayType);
  
//       // Hide the refresher once loading is done
//       this.categories.subscribe(data => {
//         refresher.complete();
//       });
//     } else {
//       // Load with Group key and custom TTL
//     this.categories = this.cache.loadFromObservable(url, req, this.categoriesKey, ttl);
  
//       // Or just load without additional settings
//     this.categories =  this.cache.loadFromObservable(url, req, this.categoriesKey);
  }
 
    getSubcategiesNative(category) {
    // let loading = await this.loadingCtrl.create();
    // await loading.present();
    let headers = {
      'Content-Type': 'application/json'
    };
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      // Return the cached data from Storage
      return from(this.getLocalData('subcategories'));
    } else {
    let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Category?ParentId='+category, {}, headers)
    console.log('this is native');
    from(nativeCall).pipe(
      finalize(() => console.log('oke'))
    )
    .subscribe(data => {

      let parsed = JSON.parse(data.data);
      this.setLocalData('subcategories', data); 
      this.subcategories = parsed.stNavMenu.arrMenuGroup;
      console.log(this.subcategories);
      console.log(data);
    }, err => {
      console.log('JS Call error: ', err);
    });
  }
  }
 
  getSubcategiesswitch(category) {
    if (this.plt.is('cordova')) {
      this.getSubcategiesNative(category);
    } else {
      this.getSubCategories(category);
    }
  }

  private setLocalData(key, data) {
    this.storage.set(`${categories_KEY}-${key}`, data);
    this.storage.set(`${subcategories_KEY}-${key}`, data);

  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${categories_KEY}-${key}`),
    this.storage.get(`${subcategories_KEY}-${key}`);
  }

//   getCategories(refresher?){
//     let body = '{ "ProcedureName":"GPR_DOWNLOAD", "DB":"Web_EO2", "BV":0 }';
//     let headers = new Headers();
//     let postData = JSON.stringify({ "Type":"ARTICLEIMAGE", "ID":"01101.20153N", "Part":"1" });
//     // let content = '600';
//     // headers.set('Checksum' , '_\u2022\u00f7\u00f4m\u0012\u00d0z\u0160?7\u001c00\u00a3>\u00c7Vm\u00e5;\u00a1D\u00ca\u203a\u2020b\u00b5\u00aa\u017dB\u00f4');
//     // headers.set('Accept' , '*/*');
//     // headers.set('User-Agent' , 'MSG');
//     // headers.set('Host' , 'onerest.msgnl.com');
//     headers.append('Access-Control-Allow-Origin' , '*');
//     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
//     headers.append('Accept','application/json');
//     headers.append('content-type','application/json');
//     // headers.set('X-Forwarded-Proto', 'https');
//     // headers.set('X-Forwarded-For', '5.102.140.34');
//     // headers.set('X-Forwarded-Host', 'onerest.msgnl.com');
//     // headers.set('Connection', 'Keep-Alive');
//     // headers.set('Content-Lenght', postData);
//     let url = '/Category';
//     // var requestoptions = new RequestOptions({
//     //   headers: headers,
//     //   body: postData
//     // })
//     console.log(postData); 
//     let req = this.http.get(url, {headers:headers})
//       .map(res => {
//         // let toast = this.toastCtrl.create({
//         //   message: 'Artikelen Laden',
//         //   duration: 2000
//         // });
//         // toast.present();
//         const array = JSON.stringify(res.json().stNavMenu.arrMenuGroup );
//         console.log(array);
//         const object = JSON.parse(array)
//         console.log(object);
//         let json2 = JSON.stringify(object);
//         let json1 = JSON.parse(json2);
//         return json1 || [];
//       });
  
//       let ttl = 10;
//       console.log(url);
//       console.log(req);
//     // Specify custom TTL if you want
  
  
//     if (refresher) {
//       // Reload data even if it is cached
//       let delayType = 'all';
//       this.categories = this.cache.loadFromDelayedObservable(url, req, this.categoriesKey, ttl, delayType);
  
//       // Hide the refresher once loading is done
//       this.categories.subscribe(data => {
//         refresher.complete();
//       });
//     } else {
//       // Load with Group key and custom TTL
//     this.categories = this.cache.loadFromObservable(url, req, this.categoriesKey, ttl);
  
//       // Or just load without additional settings
//     this.categories =  this.cache.loadFromObservable(url, req, this.categoriesKey);
//   }
// }

// getSubCategories(category, refresher?){
//   let body = '{ "ProcedureName":"GPR_DOWNLOAD", "DB":"Web_EO2", "BV":0 }';
//   let headers = new Headers();
//   let postData = JSON.stringify({ "Type":"ARTICLEIMAGE", "ID":"01101.20153N", "Part":"1" });
//   // let content = '600';
//   // headers.set('Checksum' , '_\u2022\u00f7\u00f4m\u0012\u00d0z\u0160?7\u001c00\u00a3>\u00c7Vm\u00e5;\u00a1D\u00ca\u203a\u2020b\u00b5\u00aa\u017dB\u00f4');
//   // headers.set('Accept' , '*/*');
//   // headers.set('User-Agent' , 'MSG');
//   // headers.set('Host' , 'onerest.msgnl.com');
//   // headers.append("Accept", 'application/json');
//   headers.append('Content-Type', 'application/json' );
//   // headers.set('X-Forwarded-Proto', 'https');
//   // headers.set('X-Forwarded-For', '5.102.140.34');
//   // headers.set('X-Forwarded-Host', 'onerest.msgnl.com');
//   // headers.set('Connection', 'Keep-Alive');
//   // headers.set('Content-Lenght', postData);
//   let url = '/Category?ParentId='+category;
//   // var requestoptions = new RequestOptions({
//   //   headers: headers,
//   //   body: postData
//   // })
//   console.log(postData); 
//   let req = this.http.get(url, {headers:headers})
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stNavMenu.arrMenuGroup );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       return json1 || [];
//     });

//     let ttl = 10;
//     console.log(url);
//     console.log(req);
//   // Specify custom TTL if you want


//   if (refresher) {
//     // Reload data even if it is cached
//     let delayType = 'all';
//     this.subcategories = this.cache.loadFromDelayedObservable(url, req, this.subcategoriesKey, ttl, delayType);

//     // Hide the refresher once loading is done
//     this.subcategories.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//     // Load with Group key and custom TTL
//   this.subcategories = this.cache.loadFromObservable(url, req, this.subcategoriesKey, ttl);

//     // Or just load without additional settings
//   this.subcategories =  this.cache.loadFromObservable(url, req, this.subcategoriesKey);
// }
// }




//   getCountries(){
//       this.countryRef.once('value', (snap) => {
//         this.countries = [];
//         if (snap.val()) {
//           var tempCountry = snap.val();
//           for (var key in tempCountry) {
//             let singleCategory = {
//               id:key,
//               CRITERETEC:tempCountry[key].CRITERETEC,
//               LIBLONG: tempCountry[key].LIBLONG,
//               SmallName:tempCountry[key].SmallName
//             };
  
//             this.countries.push(singleCategory);
//           }
//         }
//         this.events.publish('countryLoaded');
//       });
//   }

//   getPrice1(){
//     this.price1Ref.once('value', (snap) => {
//       this.price1 = [];
//       if (snap.val()) {
//         var tempPrice1 = snap.val();
//         for (var key in tempPrice1) {
//           let singleCategory = {
//             id:key,
//             CRITERETEC:tempPrice1[key].CRITERETEC,
//             LIBLONG: tempPrice1[key].LIBLONG,
//             SmallName:tempPrice1[key].SmallName
//           };

//           this.price1.push(singleCategory);
//         }
//       }
//       this.events.publish('price1Loaded');
//     });
// }

//   getGrapeVariety(){
//     this.grapeRef.once('value', (snap) => {
//       this.grapevariety = [];
//       if (snap.val()) {
//         var tempGrapeVariety = snap.val();
//         for (var key in tempGrapeVariety) {
//           let singleCategory = {
//             id:key,
//             CRITERETEC:tempGrapeVariety[key].CRITERETEC,
//             LIBLONG: tempGrapeVariety[key].LIBLONG,
//             SmallName:tempGrapeVariety[key].SmallName
//           };

//           this.grapevariety.push(singleCategory);
//         }
//       }
//       this.events.publish('grapeLoaded');
//     });
// }

}
