import { CacheService } from 'ionic-cache';
import { Injectable } from '@angular/core';
import { Events, ToastController, Platform, LoadingController  } from '@ionic/angular';
import { Storage } from '@ionic/storage';
// import firebase from 'firebase';
// import { AngularFireDatabaseModule } from "@angular/fire/database";
import { DataFinder } from '../../providers/datafinder';
// const products_KEY = 'products';
// import { DataFinder } from '../../providers/datafinder';
import { NetworkService, ConnectionStatus } from '../../services/network.service';
import { HTTP } from '@ionic-native/http/ngx';
import { Observable} from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { finalize } from 'rxjs/operators';
import { AuthProvider } from '../../providers/auth/auth';
import 'rxjs/add/operator/map';
import { File } from '@ionic-native/file/ngx';
import { from } from 'rxjs';
const API_STORAGE_KEY = 'specialkey';
@Injectable()
export class ProductsProvider {
  // promoRef = firebase.database().ref("promotions");
  // productRef = firebase.database().ref("articleinitial");
  products: any;
  orders: Observable<any>;
  pbms: Observable<any>;
  order: Observable<any>;
  pbm: Observable<any>;
  promo: any;
  filters: any;
  sale: any;
  search: any;
  suggestion: Observable<any>;
  res: any;
  object: any;
  keys:any;
  suggestionKey = 'my-suggestion-group';
  searchKey = 'my-search-group';
  productsKey = 'my-products-group';
  promoKey = 'my-promo-group';
  saleKey = 'my-sale-group';
  filtersKey = 'my-filters-group';
  ordersKey = 'my-orders-group';
  orderKey = 'my-order-group';
  pbmsKey = 'my-pbms-group';
  pbmKey = 'my-pbm-group';
  // articleinitial = [];
  // promos: Array<any> = [];
  // searchTerm:Array<any> =[];  
  constructor(private networkService: NetworkService, private http1: Http, private loadingCtrl: LoadingController, private nativeHttp: HTTP, private file: File,public plt: Platform, private auth:AuthProvider,private toastCtrl:ToastController, private http: HttpClient, private cache: CacheService, public events: Events, public storage: Storage,  private dataFinder : DataFinder, ) {
  }

   getPromoSlider() {
    let loading =  this.loadingCtrl.create();
     
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept','application/json');
    headers.append('content-type','application/json');
    let req = this.http.get('https://demorest.msgsoftware.nl/Promotion', {headers:headers}).pipe(
      finalize(() => console.log('ok'))
    )
    .subscribe(data => {
      this.promo = data['stActions']['arrAction'];
      console.log(data['stActions']['arrAction']);
      console.log(this.promo);
      return this.promo
    }, err => {
      console.log('JS Call error: ', err);
    });
  }
 
   getPromoSliderNative() {
    let loading =  this.loadingCtrl.create();
     
    let headers = {
      'Content-Type': 'application/json'
    };
    let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Promotion', {}, headers)
    console.log('this is native');
    from(nativeCall).pipe(
      finalize(() => console.log('ok'))
    )
    .subscribe(data => {

      let parsed = JSON.parse(data.data);
      this.promo = parsed.stActions.arrAction;
      console.log(this.promo);
      console.log(data);
    }, err => {
      console.log('JS Call error: ', err);
    });
  }

  getPromoSwitch() {
    if (this.plt.is('cordova')) {
      this.getPromoSliderNative();
    } else {
      this.getPromoSlider();
    }
  }

   getSale() {
    let loading =  this.loadingCtrl.create();
     
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept','application/json');
    headers.append('content-type','application/json');
    let req = this.http.get('https://demorest.msgsoftware.nl/Promotion', {headers:headers}).pipe(
      finalize(() => console.log('ok'))
    )
    .subscribe(data => {
      this.sale = data['stActions']['arrAction'];
      console.log(data['stActions']['arrAction']);
      console.log(this.sale);
      return this.sale
    }, err => {
      console.log('JS Call error: ', err);
    });
  }
 
   getSaleNative() {
    let loading =  this.loadingCtrl.create();
     
    let headers = {
      'Content-Type': 'application/json'
    };
    let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Promotion', {}, headers)
    console.log('this is native');
    from(nativeCall).pipe(
      finalize(() => console.log('ok'))
    )
    .subscribe(data => {

      let parsed = JSON.parse(data.data);
      this.sale = parsed.stActions.arrAction;
      console.log(this.sale);
      console.log(data);
    }, err => {
      console.log('JS Call error: ', err);
    });
  }
 
  getSaleSwitch() {
    if (this.plt.is('cordova')) {
      this.getSaleNative()
    } else {
      this.getSale();
    }
  }

   getProductsByCategoryFilters(categoryfilter) {
    let loading =  this.loadingCtrl.create();
     
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept','application/json');
    headers.append('content-type','application/json');
    let url = '/Article?Category='+categoryfilter;
    let req = this.http.get(url, {headers:headers}).pipe(
      finalize(() => console.log('ok'))
    )
    .subscribe(data => {
      this.products = data['stArticles']['arrArticleInList'];
      console.log(data['stArticles']['arrArticleInList']);
      console.log(this.products);
      return this.products
    }, err => {
      console.log('JS Call error: ', err);
    });
  }
 
   getProductsCategoryFiltersNative(categoryfilter) {
    let loading =  this.loadingCtrl.create();
     
    let headers = {
      'Content-Type': 'application/json'
    };
    let url = 'https://demorest.msgsoftware.nl/Article?Category='+categoryfilter;
    let nativeCall = this.nativeHttp.get(url, {}, headers)
    console.log('this is native');
    from(nativeCall).pipe(
      finalize(() => console.log('ok'))
    )
    .subscribe(data => {

      let parsed = JSON.parse(data.data);
      this.products = parsed.stArticles.arrArticleInList;
      console.log(this.products);
      console.log(data);
    }, err => {
      console.log('JS Call error: ', err);
    });
  }
 
  getProductCategoryFiltersNativeSwitch(categoryfilter) {
    if (this.plt.is('cordova')) {
      this.getProductsCategoryFiltersNative(categoryfilter)
    } else {
      this.getProductsByCategoryFilters(categoryfilter);
    }
  }

  getOrderlist(refresher?){
    let headers = new Headers();
    let options = new RequestOptions({ headers: headers, withCredentials: true });
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Content-Type', 'application/json' );
    headers.append('UserToken', this.auth.AuthToken)
    console.log('orderlist-token', this.auth.AuthToken)
    let url = '/Orderlist'; 
    let req = this.http1.get(url, options)
      .map(res => {
        let toast = this.toastCtrl.create({
          message: 'Artikelen Laden',
          duration: 2000
        });
        // toast.present();
        const array = JSON.stringify(res.json().stListNames.arrListName );
        console.log(array);
        const object = JSON.parse(array)
        console.log(object);
        let json2 = JSON.stringify(object);
        let json1 = JSON.parse(json2);
        const array1 = JSON.stringify(res.json());
        console.log(array);
        const object2 = JSON.parse(array1)
        console.log(object);
        let json4 = JSON.stringify(object2);
        let json3 = JSON.parse(json4);
        return json1 || [] && json3 || [];
      });
    let ttl = 10;
    if (refresher) {
      let delayType = 'all';
      this.orders = this.cache.loadFromDelayedObservable(url, req, this.ordersKey, ttl, delayType);
      this.orders.subscribe(data => {
        refresher.complete();
      });
    } else {
    this.orders = this.cache.loadFromObservable(url, req, this.ordersKey, ttl);
    this.orders =  this.cache.loadFromObservable(url, req, this.ordersKey);
    }
  }
 
   getOrderlistNative() {
    let loading =  this.loadingCtrl.create();
    console.log('token', this.auth.AuthToken);
    this.storage.get('token').then((val) => {
      console.log('Your age is', val);
      val = this.auth.AuthToken
    });
    let headers = {
      'Content-Type': 'application/json',
      'UserToken': 'this.auth.AuthToken'
    };
    let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Orderlist',this.auth.AuthToken, headers)
    console.log('this is native');
    console.log(this.auth.AuthToken)
    from(nativeCall).pipe(
      finalize(() => console.log('ok'))
    )
    .subscribe(data => {

      let parsed = JSON.parse(data.data);
      this.orders = parsed.stListNames.arrListName;
      console.log(this.orders);
      console.log(data);
    }, err => {
      console.log('JS Call error: ', err);
    });
  }
 
  getOrderlistSwitch() {
    if (this.plt.is('cordova')) {
      this.getOrderlistNative()
    } else {
      this.getOrderlist();
    }
  }


     getOrderlistItems(order) {
      let loading =  this.loadingCtrl.create();
       
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      let url = '/Orderlist/'+order;
      let req = this.http.get(url, {headers:headers}).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
        this.order = data['stArticles']['arrArticleInList'];
        console.log(data['stArticles']['arrArticleInList']);
        console.log(this.order);
        return this.order
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
     getOrderlistItemsNative(order) {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Orderlist'+order, {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.order = parsed.stArticles.arrArticleInList;
        console.log(this.order);
        console.log(data);
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
    getOrderlistItemsSwitch(order) {
      if (this.plt.is('cordova')) {
        this.getOrderlistItemsNative(order)
      } else {
        this.getOrderlistItems(order);
      }
    }

     getPBMList() {
      let loading =  this.loadingCtrl.create();
       
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', 'demopbm');
      let url = '/Orderlist';
      let req = this.http.get(url, {headers:headers}).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
        this.pbms = data['stListNames']['arrListName'];
        console.log(data['stListNames']['arrListName']);
        console.log(this.pbms);
        return this.pbms
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
     getPBMListNative() {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json',
        'UserToken': 'demopbm'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Orderlist', {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.pbms = parsed.stListNames.arrListName;
        console.log(this.pbms);
        console.log(data);
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
    getPBMListSwitch() {
      if (this.plt.is('cordova')) {
        this.getPBMListNative()
      } else {
        this.getPBMList();
      }
    }


     getPBMListItems(pbm) {
      let loading =  this.loadingCtrl.create();
       
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', 'demopbm');
      let url = '/Orderlist/'+pbm; 
      let req = this.http.get(url, {headers:headers}).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
        this.pbm = data['stArticles']['arrArticleInList'];
        console.log(data['stArticles']['arrArticleInList']);
        console.log(this.pbm);
        return this.pbm
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
     getPBMListItemsNative(pbm) {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json',
        'UserToken': 'demopbm'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Orderlist'+pbm, {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.pbm = parsed.stArticles.arrArticleInList;
        console.log(this.pbm);
        console.log(data);
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
    getPBMListItemsSwitch(pbm) {
      if (this.plt.is('cordova')) {
        this.getPBMListItemsNative(pbm)
      } else {
        this.getPBMListItems(pbm);
      }
    }

     getProductsByCategory(category) {
        let loading =  this.loadingCtrl.create();
        if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
          // Return the cached data from Storage
          return from(this.getLocalData('users'));
        } else {
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin' , '*');
        headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
        headers.append('Accept','application/json');
        headers.append('content-type','application/json');
        headers.append('UserToken', this.auth.AuthToken);
        console.log(this.auth.AuthToken);
        let url = '/Article?Category='+category; 
        let req = this.http.get(url, {headers:headers}).pipe(
          finalize(() => console.log('ok'))
        )
        .subscribe(data => {
          this.products = data['stArticles']['arrArticleInList'];
          console.log(data['stArticles']['arrArticleInList']);
          console.log(this.products);
            let ttl = 10;
            this.products = this.cache.loadFromObservable(url, req, this.productsKey, ttl);
            this.products =  this.cache.loadFromObservable(url, req, this.productsKey);
            // console.log('ok');
          this.setLocalData('category', data);
          return this.products
        }, err => {
          console.log('JS Call error: ', err);
        });
      }
    }
    

//     getProductsByCategory(category,refresher?){
//   let headers = new Headers();
//   let options = new RequestOptions({ headers: headers, withCredentials: true });
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', this.auth.AuthToken)
//   console.log(this.auth.AuthToken)
//   let url = '/Article?Category='+category; 
//   console.log(url);
//   let loading = this.loadingCtrl.create();
//   // 
//   let req = this.http.get(url, options)
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stArticles.arrArticleInList );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       const array1 = JSON.stringify(res.json().stPropFilter.arrProp );
//       console.log(array);
//       const object2 = JSON.parse(array1)
//       console.log(object);
//       let json4 = JSON.stringify(object2);
//       let json3 = JSON.parse(json4);
//       return json1 || [] && json3 || [];
//     });
//   let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.products = this.cache.loadFromDelayedObservable(url, req, this.productsKey, ttl, delayType);
//     this.products.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.products = this.cache.loadFromObservable(url, req, this.productsKey, ttl);
//   this.products =  this.cache.loadFromObservable(url, req, this.productsKey);
//   // console.log('ok');
//   }
// }
   
     getProductsByCategoryNative(category) {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Article?Category='+category, {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.products = parsed.stArticles.arrArticleInList;
        console.log(this.products);
        console.log(data);
      }, err => {
        console.log('native Call error: ', err);
      });
    }
   
    getProductsByCategorySwitch(category) {
      if (this.plt.is('cordova')) {
        this.getProductsByCategoryNative(category);
      } else {
        this.getProductsByCategory(category);
      }
    }

     getProductsByFilters(category) {
      let loading =  this.loadingCtrl.create();
       
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.auth.AuthToken)
      let url = '/Article?Category='+category; 
      let req = this.http.get(url, {headers:headers}).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
        this.filters = data['stPropFilter']['arrProp'];
        console.log(data['stPropFilter']['arrProp']);
        console.log(this.filters);
        return this.filters
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
     getProductsByFiltersNative(category) {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Article?Category='+category, {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.filters = parsed.stPropFilter.arrProp;
        console.log(this.filters);
        console.log(data);
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
    getProductsByFilterSwitch(category) {
      if (this.plt.is('cordova')) {
        this.getProductsByFiltersNative(category);
      } else {
        this.getProductsByFilters(category);
      }
    }

     getProductsByFilterID(category,filter1) {
      let loading =  this.loadingCtrl.create();
       
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.auth.AuthToken)
      let url = '/Article?Category='+category+filter1; 
      let req = this.http.get(url, {headers:headers}).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
        this.products = data['stArticles']['arrArticleInList'];
        console.log(data['stArticles']['arrArticleInList']);
        console.log(this.products);
        return this.products
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
     getProductsByFilterIDNative(category,filter1) {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Article?Category='+category+filter1, {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.products = parsed.stArticles.arrArticleInList;
        console.log(this.products);
        console.log(data);
      }, err => {
        console.log('JS Call error: ', err);

      });
    }
   
    getProductsByFilterIDSwitch(category,filter1) {
      if (this.plt.is('cordova')) {
        this.getProductsByFilterIDNative(category,filter1);
      } else {
        this.getProductsByFilterID(category,filter1);
      }
    }

     getSearchProducts(item) {
      let loading =  this.loadingCtrl.create();
       
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.auth.AuthToken)
      let url = '/Article?SearchValue='+item;  
      let req = this.http.get(url, {headers:headers}).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
        this.search = data['stArticles']['arrArticleInList'];
        console.log(data['stArticles']['arrArticleInList']);
        console.log(this.search);
        return this.search
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
     getSearchProductsNative(item) {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Article?SearchValue='+item, {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.search = parsed.stArticles.arrArticleInList;
        console.log(this.search);
        console.log(data);
      }, err => {
        console.log('JS Call error: ', err);
        
      });
    }
   
    getSearchProductsNativeSwitch(item) {
      if (this.plt.is('cordova')) {
        this.getSearchProductsNative(item);
      } else {
        this.getSearchProducts(item);
      }
    }


     getSearchSuggestions(sWord) {
      let loading =  this.loadingCtrl.create();
       
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.auth.AuthToken)
      let url = '/Searchword/Suggestion/'+sWord; 
      let req = this.http.get(url, {headers:headers}).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
        this.suggestion = data['stSearchwords'];
        console.log(data['stSearchwords']);
        console.log(this.suggestion);
        return this.suggestion
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
     getSearchSuggestionNative(sWord) {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Searchword/Suggestion/'+sWord, {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.suggestion = parsed.stSearchwords;
        console.log(this.search);
        console.log(data);
      }, err => {
        console.log('JS Call error: ', err);
        
      });
    }
   
    getSearchSuggestionsNativeSwitch(sWord) {
      if (this.plt.is('cordova')) {
        this.getSearchSuggestionNative(sWord);
      } else {
        this.getSearchSuggestions(sWord);
      }
    }

     getProducts() {
      let loading =  this.loadingCtrl.create();
       
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.auth.AuthToken)
      let url = '/Category'; 
      let req = this.http.get(url, {headers:headers}).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
        this.products = data['Result'];
        console.log(data['Result']);
        console.log(this.products);
        return this.products
      }, err => {
        console.log('JS Call error: ', err);
      });
    }
   
     getProductsNative() {
      let loading =  this.loadingCtrl.create();
       
      let headers = {
        'Content-Type': 'application/json'
      };
      let nativeCall = this.nativeHttp.get('https://demorest.msgsoftware.nl/Category', {}, headers)
      console.log('this is native');
      from(nativeCall).pipe(
        finalize(() => console.log('ok'))
      )
      .subscribe(data => {
  
        let parsed = JSON.parse(data.data);
        this.suggestion = parsed.stSearchwords;
        console.log(this.search);
        console.log(data);
      }, err => {
        console.log('JS Call error: ', err);
        
      });
    }
   
    getProductsNativeSwitch() {
      if (this.plt.is('cordova')) {
        this.getProducts();
      } else {
        this.getProductsNative();
      }
    }


          // Save result of API requests
          private setLocalData(key, data) {
            this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
          }
        
          // Get cached API result
          private getLocalData(key) {
            return this.storage.get(`${API_STORAGE_KEY}-${key}`);
          }


    

//   getPromoSlider(refresher?) {
//     if (this.plt.is('android')) {

//       // const httpHeader = {
//       //   'Content-Type':  'application/json',
//       // };
//       this.nativehttp.get('https://demorest.msgsoftware.nl/Promotion', {}, {})
//       .then(res => {
    
//         // JSON.parse(res.json());
//         console.log(res); // data received by server
//         this.promo = res
//       })
//       .catch(error => {
    
//         console.log(error.status);
//         console.log(error.error); // error message as string
//         console.log(error.headers);
    
//       });
//     }
//     let headers = new Headers();
//     headers.append('Access-Control-Allow-Origin' , '*');
//     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
//     headers.append('Accept','application/json');
//     headers.append('content-type','application/json');
//     let url = '/Promotion';
//     let req = this.http.get(url, {headers:headers})
//       .map(res => {
//         const array = JSON.stringify(res.json().stActions.arrAction );
//         const object = JSON.parse(array)
//         let json2 = JSON.stringify(object);
//         let json1 = JSON.parse(json2);
//         return json1 || [];
//       });
//       let ttl = 10;
//     if (refresher) {
//       let delayType = 'all';
//       this.promo = this.cache.loadFromDelayedObservable(url, req, this.promoKey, ttl, delayType);
//       this.promo.subscribe(data => {
//         refresher.complete();
//       });
//     } else {
//     this.promo = this.cache.loadFromObservable(url, req, this.promoKey, ttl);
//     this.promo =  this.cache.loadFromObservable(url, req, this.promoKey);
//   }
// }

// getSale(refresher?) {
//     let headers = new Headers();
//     headers.append('Access-Control-Allow-Origin' , '*');
//     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
//     headers.append('Accept','application/json');
//     headers.append('content-type','application/json');
//     let url = '/Promotion';
//     let req = this.http.get(url, {headers:headers})
//       .map(res => {
//         const array = JSON.stringify(res.json().stActions.arrAction );
//         const object = JSON.parse(array)
//         let json2 = JSON.stringify(object);
//         let json1 = JSON.parse(json2);
//         return json1 || [];
//       });
//       let ttl = 10;
//     if (refresher) {
//       let delayType = 'all';
//       this.sale = this.cache.loadFromDelayedObservable(url, req, this.saleKey, ttl, delayType);
//       this.sale.subscribe(data => {
//         refresher.complete();
//       });
//     } else {
//     this.sale = this.cache.loadFromObservable(url, req, this.saleKey, ttl);
//     this.sale =  this.cache.loadFromObservable(url, req, this.saleKey);
//   }
// }

// getProductsByCategoryFilters(categoryfilter,refresher?){
//   let headers = new Headers();
//   let options = new RequestOptions({ headers: headers, withCredentials: true });
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', this.auth.AuthToken)
//   console.log(this.auth.AuthToken)
//   let url = '/Article?Category='+categoryfilter; 
//   let req = this.http.get(url, options)
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stArticles.arrArticleInList );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       this.file.copyFile(this.file.applicationDirectory + '/www/assets/data', 'data.json', this.file.applicationStorageDirectory, 'data.json').then(_ => console.log('Directory exists')).catch(err => console.log('Directory doesnt exist'));

//       const array1 = JSON.stringify(res.json().stPropFilter.arrProp );
//       console.log(array);
//       const object2 = JSON.parse(array1)
//       console.log(object);
//       let json4 = JSON.stringify(object2);
//       let json3 = JSON.parse(json4);
//       return json1 || [] && json3 || [];
//     });
//   let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.products = this.cache.loadFromDelayedObservable(url, req, this.productsKey, ttl, delayType);
//     this.products.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.products = this.cache.loadFromObservable(url, req, this.productsKey, ttl);
//   this.products =  this.cache.loadFromObservable(url, req, this.productsKey);
//   }
// }

// getOrderlist(refresher?){
//   let headers = new Headers();
//   let options = new RequestOptions({ headers: headers, withCredentials: true });
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', this.auth.AuthToken)
//   console.log(this.auth.AuthToken)
//   let url = '/Orderlist'; 
//   let req = this.http.get(url, options)
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stListNames.arrListName );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       const array1 = JSON.stringify(res.json());
//       console.log(array);
//       const object2 = JSON.parse(array1)
//       console.log(object);
//       let json4 = JSON.stringify(object2);
//       let json3 = JSON.parse(json4);
//       return json1 || [] && json3 || [];
//     });
//   let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.orders = this.cache.loadFromDelayedObservable(url, req, this.ordersKey, ttl, delayType);
//     this.orders.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.orders = this.cache.loadFromObservable(url, req, this.ordersKey, ttl);
//   this.orders =  this.cache.loadFromObservable(url, req, this.ordersKey);
//   }
// }

// getOrderlistItems(order, refresher?){
//   let headers = new Headers();
//   let options = new RequestOptions({ headers: headers, withCredentials: true });
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', this.auth.AuthToken)
//   console.log(this.auth.AuthToken)
//   let url = '/Orderlist/'+order; 
//   let req = this.http.get(url, options)
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stArticles.arrArticleInList);
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       const array1 = JSON.stringify(res.json().stArticles.arrArticleInList);
//       console.log(array);
//       const object2 = JSON.parse(array1)
//       console.log(object);
//       let json4 = JSON.stringify(object2);
//       let json3 = JSON.parse(json4);
//       return json1 || [] && json3 || [];
//     });
//   let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.order = this.cache.loadFromDelayedObservable(url, req, this.orderKey, ttl, delayType);
//     this.order.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.order = this.cache.loadFromObservable(url, req, this.orderKey, ttl);
//   this.order =  this.cache.loadFromObservable(url, req, this.orderKey);
//   }
// }

// getPBMlist(refresher?){
//   let headers = new Headers();
//   let options = new RequestOptions({ headers: headers, withCredentials: true });
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', 'demopbm')
//   console.log(this.auth.AuthToken)
//   console.log(this.auth.UserID)
//   let url = '/Orderlist'; 
//   let req = this.http.get(url, options)
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stListNames.arrListName );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       const array1 = JSON.stringify(res.json());
//       console.log(array);
//       const object2 = JSON.parse(array1)
//       console.log(object);
//       let json4 = JSON.stringify(object2);
//       let json3 = JSON.parse(json4);
//       return json1 || [] && json3 || [];
//     });
//   let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.pbms = this.cache.loadFromDelayedObservable(url, req, this.pbmsKey, ttl, delayType);
//     this.pbms.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.pbms = this.cache.loadFromObservable(url, req, this.pbmsKey, ttl);
//   this.pbms =  this.cache.loadFromObservable(url, req, this.pbmsKey);
//   }
// }

// getPBMlistItems(pbm, refresher?){
//   let headers = new Headers();
//   let options = new RequestOptions({ headers: headers, withCredentials: true });
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', 'demopbm')
//   console.log(this.auth.AuthToken)
//   let url = '/Orderlist/'+pbm; 
//   let req = this.http.get(url, options)
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stArticles.arrArticleInList);
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       const array1 = JSON.stringify(res.json().stArticles.arrArticleInList);
//       console.log(array);
//       const object2 = JSON.parse(array1)
//       console.log(object);
//       let json4 = JSON.stringify(object2);
//       let json3 = JSON.parse(json4);
//       return json1 || [] && json3 || [];
//     });
//   let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.pbm = this.cache.loadFromDelayedObservable(url, req, this.pbmKey, ttl, delayType);
//     this.pbm.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.pbm = this.cache.loadFromObservable(url, req, this.pbmKey, ttl);
//   this.pbm =  this.cache.loadFromObservable(url, req, this.pbmKey);
//   }
// }

// getProductsByCategory(category,refresher?){
//   let headers = new Headers();
//   let options = new RequestOptions({ headers: headers, withCredentials: true });
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', this.auth.AuthToken)
//   console.log(this.auth.AuthToken)
//   let url = '/Article?Category='+category; 
//   console.log(url);
//   let loading = this.loadingCtrl.create();
//   // 
//   let req = this.http.get(url, options)
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stArticles.arrArticleInList );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       const array1 = JSON.stringify(res.json().stPropFilter.arrProp );
//       console.log(array);
//       const object2 = JSON.parse(array1)
//       console.log(object);
//       let json4 = JSON.stringify(object2);
//       let json3 = JSON.parse(json4);
//       return json1 || [] && json3 || [];
//     });
//   let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.products = this.cache.loadFromDelayedObservable(url, req, this.productsKey, ttl, delayType);
//     this.products.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.products = this.cache.loadFromObservable(url, req, this.productsKey, ttl);
//   this.products =  this.cache.loadFromObservable(url, req, this.productsKey);
//   // console.log('ok');
//   }
// }

// getProductsByCategoryNative(category,refresher?){
//   // let headers = new Headers();
//   // let options = new RequestOptions({ headers: headers, withCredentials: true });
//   // headers.append('Access-Control-Allow-Origin' , '*');
//   // headers.append('Content-Type', 'application/json' );
//   // headers.append('UserToken', this.auth.AuthToken)
//   console.log(this.auth.AuthToken)
//   let url = 'http://testrestservicesdyk.msgnl.com/Article?Category='+category; 
//   console.log(url);
//   // let loading = this.loadingCtrl.create();
//   // 
//   //       let toast = this.toastCtrl.create({
//   //       message: 'native artikelen ophalen',
//   //       duration: 2000
//   //     });
//   //     toast.present();
//   let data = {
//     'Action': 'Login',
//     'UserName': 'bla',
//     'Password': 'blabla'
//   };
//   let headers = {
//       'Content-Type': 'application/json'
//   };
//   // let req = this.http.get(url, options)
//   //   .map(res => {
//   //     let toast = this.toastCtrl.create({
//   //       message: 'native artikelen ophalen',
//   //       duration: 2000
//   //     });
//   //     toast.present();
//   //     const array = JSON.stringify(res.json().stArticles.arrArticleInList );
//   //     console.log(array);
//   //     const object = JSON.parse(array)
//   //     console.log(object);
//   //     let json2 = JSON.stringify(object);
//   //     let json1 = JSON.parse(json2);
//   //     const array1 = JSON.stringify(res.json().stPropFilter.arrProp );
//   //     console.log(array);
//   //     const object2 = JSON.parse(array1)
//   //     console.log(object);
//   //     let json4 = JSON.stringify(object2);
//   //     let json3 = JSON.parse(json4);
//   //     return json1 || [] && json3 || [];
//   //   });
//    let req = this.nativeHttp.get(url, {}, headers)
//                     .then((data) => {
//                         console.log(data);
//                     })
//                     .catch((error) => {
//                         console.log(error);
//                     });
//   let ttl = 10;
//   // if (refresher) {
//   //   let delayType = 'all';
//   //   this.products = this.cache.loadFromDelayedObservable(url, req, this.productsKey, ttl, delayType);
//   //   this.products.subscribe(data => {
//   //     refresher.complete();
//   //   });
//   // } else {
//   this.products = this.cache.loadFromObservable(url, req, this.productsKey, ttl);
//   this.products =  this.cache.loadFromObservable(url, req, this.productsKey);
//   // console.log('ok');
//   // }
// }

// getProductsByFilters(category,refresher?){
//   let headers = new Headers();
//   headers.append('Content-Type', 'application/json' );
//   let url = '/Article?CategoryID='+category; 
//   let req = this.http.get(url, {headers:headers})
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stPropFilter.arrProp );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       return json1 || [];
//     });
//     let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.filters = this.cache.loadFromDelayedObservable(url, req, this.filtersKey, ttl, delayType);
//     this.filters.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.filters = this.cache.loadFromObservable(url, req, this.filtersKey, ttl);
//   this.filters =  this.cache.loadFromObservable(url, req, this.filtersKey);
//   }
// }

// getProductsfilterID(category,refresher?){
//   let headers = new Headers();
//   headers.append('Content-Type', 'application/json' );
//   let url = '/Article?CategoryID='+category+'&Filter[1]=oppervlakte|75+m²'; 
//   let req = this.http.get(url, {headers:headers})
//     .map(res => {
//       let toast = this.toastCtrl.create({
//         message: 'Artikelen Laden',
//         duration: 2000
//       });
//       toast.present();
//       const array = JSON.stringify(res.json().stArticles.arrArticleInList);
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       return json1 || [];
//     });
//     let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.filters = this.cache.loadFromDelayedObservable(url, req, this.filtersKey, ttl, delayType);
//     this.filters.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.filters = this.cache.loadFromObservable(url, req, this.filtersKey, ttl);
//   this.filters =  this.cache.loadFromObservable(url, req, this.filtersKey);
//   }
// }

// getProductsByFilterID(category,filter1,refresher?){
//   let headers = new Headers();
//   let options = new RequestOptions({ headers: headers, withCredentials: true });
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', this.auth.AuthToken)
//   console.log(this.auth.AuthToken)
//   let url = '/Article?Category='+category+filter1; 
//   console.log(url);
//   let req = this.http.get(url, options)
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stArticles.arrArticleInList );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       const array1 = JSON.stringify(res.json().stPropFilter.arrProp );
//       console.log(array);
//       const object2 = JSON.parse(array1)
//       console.log(object);
//       let json4 = JSON.stringify(object2);
//       let json3 = JSON.parse(json4);
//       return json1 || [] && json3 || [];
//     });
//   let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.products = this.cache.loadFromDelayedObservable(url, req, this.productsKey, ttl, delayType);
//     this.products.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.products = this.cache.loadFromObservable(url, req, this.productsKey, ttl);
//   this.products =  this.cache.loadFromObservable(url, req, this.productsKey);
//   }
// }

// getSearchProducts(item,refresher?){
//   let headers = new Headers();
//   headers.append('Access-Control-Allow-Origin' , '*');
//   headers.append('Content-Type', 'application/json' );
//   headers.append('UserToken', this.auth.AuthToken)
//   let url = '/Article?SearchValue='+item; 
//   let req = this.http.get(url, {headers:headers})
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stArticles.arrArticleInList );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       return json1 || [];
//     });
//     let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.search = this.cache.loadFromDelayedObservable(url, req, this.searchKey, ttl, delayType);
//     this.search.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.search = this.cache.loadFromObservable(url, req, this.searchKey, ttl);
//   this.search =  this.cache.loadFromObservable(url, req, this.searchKey);
//   }
// }


// getSearchSuggestions(sWord,refresher?){
//   let headers = new Headers();
//   headers.append('Content-Type', 'application/json' );
//   let url = '/Searchword/Suggestion/Moer'+sWord; 
//   let req = this.http.get(url, {headers:headers})
//     .map(res => {
//       // let toast = this.toastCtrl.create({
//       //   message: 'Artikelen Laden',
//       //   duration: 2000
//       // });
//       // toast.present();
//       const array = JSON.stringify(res.json().stSearchwords );
//       console.log(array);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json2 = JSON.stringify(object);
//       let json1 = JSON.parse(json2);
//       return json1 || [];
//     });
//     let ttl = 10;
//   if (refresher) {
//     let delayType = 'all';
//     this.suggestion = this.cache.loadFromDelayedObservable(url, req, this.suggestionKey, ttl, delayType);
//     this.suggestion.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.suggestion = this.cache.loadFromObservable(url, req, this.suggestionKey, ttl);
//   this.suggestion =  this.cache.loadFromObservable(url, req, this.suggestionKey);
//   }
// }

// getProducts(refresher?){
//   let body = '{ "ProcedureName":"ARTICLEINITIAL", "DB":"Web_EO2", "BV":0 }';
//   let headers = new Headers();
//   let url = '/Category';
//   let req = this.http.post(url, {headers:headers})
//     .map(res => {
//       const array = JSON.stringify(res.json().Result);
//       const object = JSON.parse(array)
//       console.log(object);
//       let json = JSON.parse(object);
//       console.log(json);
//       return json || [];
//     });
//     let ttl = 10;
//     console.log(url);
//     console.log(req);
//   if (refresher) {
//     let delayType = 'all';
//     this.products = this.cache.loadFromDelayedObservable(url, req, this.productsKey, ttl, delayType);
//     this.products.subscribe(data => {
//       refresher.complete();
//     });
//   } else {
//   this.products = this.cache.loadFromObservable(url, req, this.productsKey, ttl);
//   this.products =  this.cache.loadFromObservable(url, req, this.productsKey);
// }
// }

// forceReload(refresher){
//   this.getProductsByCategory(refresher);
// }

  initializeItems(): void {
    this.products = this.products;
  }

  query(params?: any) {
    let products=this.products;
    if (!params) {
      return products;
    }
    console.log("filtering with this",params);

    // if(params['sPropDesc']!==""){
    //   products = this.products.filter((products) => {
    //     if(params['sPropDesc']==products['sPropDesc']){
    //       return products;
    //     }
    //   });
    // }

    // if(params['sPropValue']!==""){
    //   products = this.products.filter((products) => {
    //     if(params['sPropDesc']==products['sPropDesc']){
    //       return products;
    //     }
    //   });
    // }

    // if(params['sPropUnit']!==""){
    //   products = this.products.filter((products) => {
    //     if(params['sPropUnit']==products['sPropUnit']){
    //       return products;
    //     }
    //   });
    // }
    // if(params['gender']!==""){
    //   products = this.products.filter((item) => {
    //     if(params['gender']==item['gender']){
    //       return item;
    //     }
    //   });
    // }

    // if(params['first_name']!==""){
    //   products = this.products.filter((item) => {
    //     let itemValue = item['first_name'];

    //     if (typeof itemValue == 'string' && (itemValue.toLowerCase().indexOf(params['first_name'].toLowerCase()) >= 0)) {
    //       return item;
    //     } else if (itemValue == params['first_name']) {
    //       return item;
    //     }
    //   });
    // }
    return products
}

  // CountryFilter(CRITERETEC2: any) : void
  // {
  //   this.initializeItems();
  //   // if (CRITERETEC2.trim() !== " ")
  //   // {
  //       this.products = this.products.filter((products) =>{
  //       if(products.CRITERETEC2) {
  //         if (products.CRITERETEC2.toLowerCase().indexOf(CRITERETEC2.toLowerCase()) > -1) {
  //           return true;
  //         }
  //         return false;
  //       }
  //     });
  //   // }

  // }

  // getProducts() {
  //   this.productRef.once('value', (snap) => {
  //     this.products = [];
  //     if (snap.val()) {
  //       var tempProducts = snap.val();
  //       for (var key in tempProducts) {
  //         let singleProduct = {
  //           id:key,
  //           ArticleID: tempProducts[key].ArticleID,
  //           ProdDesc: tempProducts[key].ProdDesc,
  //           Reference: tempProducts[key].Reference,
  //           NETPRICE: tempProducts[key].NETPRICE,
  //           VATPRICE: tempProducts[key].VATPRICE,
  //           // name: tempProducts[key].name,
  //           // images:tempProducts[key].images,
  //           // price:tempProducts[key].price,
  //           // rating:tempProducts[key].rating,
  //           // sale_price:tempProducts[key].sale_price,
  //           // short_description:tempProducts[key].short_description,
  //           // thumb:tempProducts[key].thumb
  //         };

  //         this.products.push(singleProduct);
  //       }
  //     }
  //     this.events.publish('productsLoaded');
  //   });
  // }

}
