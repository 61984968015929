import { Injectable } from '@angular/core';
import { OfflineManagerService } from './offline-manager.service';
import { Observable, of, throwError, from } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { Platform, Events, ToastController, LoadingController } from '@ionic/angular';
import { NetworkService, ConnectionStatus } from './network.service';
import { forkJoin } from 'rxjs';
import { Storage } from '@ionic/storage';

const API_STORAGE_KEY = 'specialkey';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient, private plt: Platform, private nativeHttp: HTTP, private networkService: NetworkService, private storage: Storage, private offlineManager: OfflineManagerService) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  getCategories(forceRefresh: boolean = false): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
    //   // Return the cached data from Storage
    //   console.log('network', this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Online)
    //   return from(this.getLocalData('categories'));
    // } else {
      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      let categories = this.http.get('https://demorest.msgsoftware.nl/Category', {headers:headers});
      console.log('category', categories);
      console.log('this is browser');
      this.setLocalData('categories', categories);
      console.log('set', this.setLocalData('categories', categories))
      console.log('get', this.getLocalData('categories'))
      return categories;
    // }
  }

  getCategoriesNative(): Promise<HTTPResponse> {
  // if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
  //     // Return the cached data from Storage
  //   console.log('network', this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Online)
  //   return this.getLocalData('categories');
  //   } else {
    let headers = {
      'Content-Type': 'application/json'
    };
    let categories = this.nativeHttp.get('https://demorest.msgsoftware.nl/Category',{},headers);
    console.log('category', categories);
    console.log('this is native');
    return categories;
  // }
  }

  getSubCategories(category): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept','application/json');
    headers.append('content-type','application/json');
    let subcategoies = this.http.get('https://demorest.msgsoftware.nl/Category?ParentId='+category, {headers:headers});
    console.log('search', subcategoies);
    return subcategoies;
  }

  getSubCategoriesNative(category): Promise<HTTPResponse> {
    let headers = {
      'Content-Type': 'application/json'
    };
    let subcategories = this.nativeHttp.get('https://demorest.msgsoftware.nl/Category?ParentId='+category,{},headers);
    console.log('category', subcategories);
    console.log('this is native');
    return subcategories;
  }

  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }
 
  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }
}
