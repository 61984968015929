import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
/*
* Uses Injected HTTP service in order to find files and return them as usable data objects
*/
export class DataFinder {

  constructor(private http: Http) {
  }

  fetchData(){
      return this.http.get('assets/data/data.json').map(
        (response) => response.json()
      )
  }
}