import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Platform } from '@ionic/angular';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Http, Headers, RequestOptions } from '@angular/http';
import { from } from 'rxjs';
import { finalize } from 'rxjs/operators';
// import * as sha512 from 'js-sha512';
import * as sha512 from 'sha512';
import * as CryptoJS from 'crypto-js';
import 'rxjs/add/operator/toPromise';
import base64url from "base64url";
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userKey = 'my-user-group';
  user: any;
  userid: any;
  PBMuserKey = 'my-user-group';
  PBMuser: any;
  isLoggedIn = false;
  AuthToken: string;
  bAllowPbm: any;
  bAllowShop: any;
  PBMAuthToken: string;
  iUserId: any;
  iClient: any;
  iPbmMode: any;
  login2:any
  iUserType: any;
  bLoggedIn:any;
  fullname: string;
  item:any;
  OrderNumber:any;
  constructor(public platform: Platform,private http: Http, private nativeHttp: HTTP, public storage: Storage) { }

  //   async login(loginParams){
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin' , '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept','application/json');
  //     headers.append('content-type','application/json');
  //     let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
  //     console.log(JSON.stringify(loginParams));
  //     let user = this.http.post(url, JSON.stringify(loginParams), options)
  //     .subscribe( data => {
  //         if(data.json().stAuthResp){
  //             this.storeUserCredentials(data.json().stAuthResp.sSessionID);
  //             this.storeUserID(data.json().stAuthResp.iUserId);
  //             this.storage.set('userToken', this.AuthToken);
  //             this.storePBM(data.json().stAuthResp.iPbmMode);
  //             console.log(data.json().stAuthResp.iPbmMode);
  //             console.log(data.json());
  //             this.userFullname(data.json().stAuthResp.sFullName);
  //             resolve(true);
  //         }
  //         else
  //             resolve(false);
  //     });
  //   });
  // }

  async login(loginParams){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
      console.log(JSON.stringify('test',loginParams));
      let user = this.http.post(url, JSON.stringify(loginParams), options)
      .subscribe( data => {
          if(data.json().stAuthResp){
              this.storeUserCredentials(data.json().stAuthResp.sSessionID);
              this.storeUserID(data.json().stAuthResp.iUserId);
              this.storeClientID(data.json().stAuthResp.iClient);
              this.storeUserType(data.json().stAuthResp.iUserType);
              this.storage.set('userToken', this.AuthToken);
              console.log(this.AuthToken);
              this.storePBM(data.json().stAuthResp.iPbmMode);
              this.storeallowPBM(data.json().stAuthResp.bAllowPbm);
              console.log(data.json().stAuthResp.iPbmMode);
              console.log(data.json());
              this.userFullname(data.json().stAuthResp.sFullName);
              resolve(true);
          }
          else
              resolve(false);
      });
    });
  }

  async passwordReset(sUser, sMail){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      let url = 'https://demorest.msgsoftware.nl/User/'+sUser+'/'+sMail; 
      console.log(JSON.stringify('test',sUser+sMail));
      let user = this.http.post(url, '', options)
      .subscribe( data => {
          if(data.json()){
            console.log(data.json())
              resolve(true);
          }
          else
              resolve(false);
      });
    });
  }

  async Guestlogin(){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers});
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
      let user = this.http.post(url,'',options)
      .subscribe( data => {
        console.log(data);
          if(data.json().stAuthResp){
              this.storeUserCredentials(data.json().stAuthResp.sSessionID);
              this.storeUserID(data.json().stAuthResp.iUserId);
              this.storeClientID(data.json().stAuthResp.iClient);
              this.storeUserType(data.json().stAuthResp.iUserType);
              this.storage.set('userToken', this.AuthToken);
              this.storeallowPBM(data.json().stAuthResp.bAllowPbm);
              console.log(this.AuthToken);
              this.storePBM(data.json().stAuthResp.iPbmMode);
              console.log(data.json().stAuthResp.iPbmMode);
              console.log(data.json());
              this.userFullname(data.json().stAuthResp.sFullName);
              resolve(true);
          }
          else
              resolve(false);
      });
      console.log(user);
    });
  }

  async logoff(){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.AuthToken)
      let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
      // console.log(JSON.stringify(loginParams));
      let user = this.http.delete(url, options)
      .subscribe( data => {
        console.log(data);
          if(data.json().stAuthResp){
              this.storeUserCredentials(data.json().stAuthResp.sSessionID);
              this.storeUserID(data.json().stAuthResp.iUserId);
              this.storeClientID(data.json().stAuthResp.iClient);
              this.storeUserType(data.json().stAuthResp.iUserType);
              this.storage.set('userToken', this.AuthToken);
              console.log(this.AuthToken);
              this.storePBM(data.json().stAuthResp.iPbmMode);
              console.log(data.json().stAuthResp.iPbmMode);
              console.log(data.json());
              this.userFullname(data.json().stAuthResp.sFullName);
              resolve(true);
          }
          else
              resolve(false);
      });
    });
  }

  async loginoffNative() {
    // let loading =  this.loadingCtrl.create();
     
    let headers = {
      "Content-Type": "application/json"
    };
    this.nativeHttp.setDataSerializer("json");
    // console.log('native', loginParams)
    // let params = loginParams;

this.nativeHttp.delete('https://demorest.msgsoftware.nl/Authorisation',{}, headers)
              .then((data) => {
      let parsed = JSON.parse(data.data);
      this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        this.storeUserID(parsed.stAuthResp.iUserId);
        this.storeClientID(parsed.stAuthResp.iClient);
        this.storeUserType(parsed.stAuthResp.iUserType);
        this.storePBM(parsed.stAuthResp.iPbmMode);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        this.userFullname(parsed.stAuthResp.sFullName);
              })
              .catch((error) => {
                  console.log(error);
              });
  }

  async PBMlogin(){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.AuthToken)
      console.log('pbmlogin', this.AuthToken)
      // var b64 = CryptoJS.AES.encrypt(plainText, SECRET).toString();
      let sha = CryptoJS.SHA512(this.AuthToken).toString(CryptoJS.enc.Base64);
      // let encode = CryptoJS.enc.Base64.stringify(sha)
      let encodedSource1 = sha.replace(/=+$/, '');
      let encodedSource2 = encodedSource1.replace(/\+/g, '-');
      let encodedSource3 = encodedSource2.replace(/\//g, '_');
      let part = encodedSource3.slice(4, 25);
      console.log('hash', sha);
      console.log('hash', encodedSource3);
      // console.log('hash', encode);
      let url = 'https://demorest.msgsoftware.nl/Authorisation/'+part+'?SetPBM=On'; 
      console.log(url)
   
      let user = this.http.get(url, options)
      .subscribe( data => {
          if(data.json().stAuthResp){
              this.storePBMUserCredentials(data.json().stAuthResp.sSessionID);
              this.storeUserID(data.json().stAuthResp.iUserId);
              this.storeClientID(data.json().stAuthResp.iClient);
              this.storeUserType(data.json().stAuthResp.iUserType);
              // this.storage.set('userToken', this.PBMAuthToken);
              // console.log(this.AuthToken);
              this.storePBM(data.json().stAuthResp.iPbmMode);
              console.log(data.json().stAuthResp.iPbmMode);
              console.log(data.json());
              this.userFullname(data.json().stAuthResp.sFullName);
              resolve(true);
          }
          else
              resolve(false);
      });
    });
  }

  async loginswitch(){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.PBMAuthToken)
      console.log('pbmlogin', this.PBMAuthToken)
      // var b64 = CryptoJS.AES.encrypt(plainText, SECRET).toString();
      let sha = CryptoJS.SHA512(this.PBMAuthToken).toString(CryptoJS.enc.Base64);
      // let encode = CryptoJS.enc.Base64.stringify(sha)
      let encodedSource1 = sha.replace(/=+$/, '');
      let encodedSource2 = encodedSource1.replace(/\+/g, '-');
      let encodedSource3 = encodedSource2.replace(/\//g, '_');
      let part = encodedSource3.slice(4, 25);
      console.log('hash', sha);
      console.log('hash', encodedSource3);
      // console.log('hash', encode);
      let url = 'https://demorest.msgsoftware.nl/Authorisation/'+part; 
      console.log(url)
   
      let user = this.http.put(url, options)
      .subscribe( data => {
          if(data.json().stAuthResp){
              this.storeUserCredentials(data.json().stAuthResp.sSessionID);
              this.storeUserID(data.json().stAuthResp.iUserId);
              this.storeClientID(data.json().stAuthResp.iClient);
              this.storeUserType(data.json().stAuthResp.iUserType);
              this.storage.set('userToken', this.AuthToken);
              console.log(this.AuthToken);
              this.storePBM(data.json().stAuthResp.iPbmMode);
              console.log(data.json().stAuthResp.iPbmMode);
              console.log(data.json());
              this.userFullname(data.json().stAuthResp.sFullName);
              resolve(true);
          }
          else
              resolve(false);
      });
    });
  }
  
  async loginNative(loginParams) {
    // let loading =  this.loadingCtrl.create();
     
    let headers = {
      "Content-Type": "application/json"
    };
    this.nativeHttp.setDataSerializer("json");
    console.log('native', loginParams)
    let params = loginParams;

this.nativeHttp.post('https://demorest.msgsoftware.nl/Authorisation', params, headers)
              .then((data) => {
      let parsed = JSON.parse(data.data);
      this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        this.storeUserID(parsed.stAuthResp.iUserId);
        this.storeClientID(parsed.stAuthResp.iClient);
        this.storeUserType(parsed.stAuthResp.iUserType);
        this.storePBM(parsed.stAuthResp.iPbmMode);
        this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        this.userFullname(parsed.stAuthResp.sFullName);
              })
              .catch((error) => {
                  console.log(error);
              });
  }

  async guestNative(loginParams): Promise<HTTPResponse> {
    // let loading =  this.loadingCtrl.create();
    let headers = {
      "Content-Type": "application/json"
    };
    let sale = this.nativeHttp.post('https://demorest.msgsoftware.nl/Authorisation', {}, headers)
    .then(
      res =>  {let parsed = JSON.parse(res.data);
        this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        console.log(parsed.stAuthResp.sSessionID)
        this.storeUserID(parsed.stAuthResp.iUserId);
        this.storeClientID(parsed.stAuthResp.iClient);
        this.storeUserType(parsed.stAuthResp.iUserType);
        this.storePBM(parsed.stAuthResp.iPbmMode);
        this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        this.userFullname(parsed.stAuthResp.sFullName);
      },
      error => error);
  console.log(sale)
      return sale
      
  }

  async GuestloginNative(loginParams) {
    // let loading =  this.loadingCtrl.create();
    return new Promise((resolve, reject) => {
      let headers = {
        'Content-Type': 'application/json'
      };
     this.nativeHttp.post('https://demorest.msgsoftware.nl/Authorisation', loginParams, headers)
        .then(
          res => resolve(res),
          error => reject(<any>error));
      });


     
//     let headers = {
//       'Content-Type': 'application/json'
//     };
//     this.nativeHttp.setDataSerializer("json");
//     console.log('native', loginParams)
//     let params = loginParams;
// console.log(headers)
// console.log( this.nativeHttp.post('https://demorest.msgsoftware.nl/Authorisation', loginParams, headers))
//     this.nativeHttp.post('https://demorest.msgsoftware.nl/Authorisation', loginParams, headers)
//     .then((data) => {
//       let parsed = JSON.parse(data.data);
//       this.storeUserCredentials(parsed.stAuthResp.sSessionID);
//         this.storeUserID(parsed.stAuthResp.iUserId);
//         this.storeClientID(parsed.stAuthResp.iClient);
//         this.storeUserType(parsed.stAuthResp.iUserType);
//         this.storePBM(parsed.stAuthResp.iPbmMode);
//         this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
//         console.log(parsed.stAuthResp.iPbmMode);
//         console.log(parsed);
//         this.userFullname(parsed.stAuthResp.sFullName);
//               })
//               .catch((error) => {
//                   console.log(error);
//               });
  }

  guestlogin(data){
        let parsed = JSON.parse(data.data);
        this.storeUserCredentials(parsed.stAuthResp.sSessionID);
  
        this.storeUserID(parsed.stAuthResp.iUserId);
        this.storeClientID(parsed.stAuthResp.iClient);
        this.storeUserType(parsed.stAuthResp.iUserType);
        this.storePBM(parsed.stAuthResp.iPbmMode);
        this.storeallowPBM(parsed.stAuthResp.bAllowPbm);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        this.userFullname(parsed.stAuthResp.sFullName);
  }

  storeUserCredentials(token) {
    window.localStorage.setItem('Authtoken', token);
    this.storage.set('Authtoken', token);
    console.log('test',token)
    this.useCredentials(token);
  }

  storePBMUserCredentials(token) {
    window.localStorage.setItem('PBMtoken', token);
    this.storage.set('PBMtoken', token);
    this.usePBMCredentials(token);
  }

  storeUserID(iUserId) {
    window.localStorage.setItem('iUserId', iUserId);
    this.storage.set('iUserId', iUserId);
    this.useUserID(iUserId);
  }

  storeUserType(iUserType) {
    window.localStorage.setItem('iUserType', iUserType);
    this.storage.set('iUserType', iUserType);
    this.useUserID(iUserType);
  }

  storeClientID(iClient) {
    window.localStorage.setItem('iClient', iClient);
    this.storage.set('iClient', iClient);
    this.useClientID(iClient);
  }

  storePBM(iPbmMode) {
    window.localStorage.setItem('iPbmMode', iPbmMode);
    this.storage.set('iPbmMode', iPbmMode);
    this.usePBMmodeID(iPbmMode);
  }

  storeOrderNumber(OrderNumber) {
    window.localStorage.setItem('OrderNumber', OrderNumber);
    this.storage.set('OrderNumber', OrderNumber);
    this.useOrderNumber(OrderNumber);
  }

  storeallowPBM(bAllowPbm) {
    window.localStorage.setItem('bAllowPbm', bAllowPbm);
    this.storage.set('bAllowPbm', bAllowPbm);
    console.log(bAllowPbm)
    this.useallowPBM(bAllowPbm);
  }

  storelogedIn(bLoggedIn) {
    window.localStorage.setItem('bLoggedIn', bLoggedIn);
    this.storage.set('bLoggedIn', bLoggedIn);
    console.log(bLoggedIn)
    this.useLogedIn(bLoggedIn);
  }

  storeallowSHOP(bAllowSHOP) {
    window.localStorage.setItem('bAllowSHOP', bAllowSHOP);
    this.storage.set('bAllowSHOP', bAllowSHOP);
    console.log(bAllowSHOP)
    this.useallowSHOP(bAllowSHOP);
  }

  useCredentials(token) {
    this.isLoggedIn = true;
    this.AuthToken = token || window.localStorage.setItem('Authtoken', token);
  }

  usePBMCredentials(token) {
    this.isLoggedIn = true;
    this.PBMAuthToken = token || window.localStorage.setItem('PBMAuthtoken', token);
  }

  useOrderNumber(token) {
    this.isLoggedIn = true;
    this.OrderNumber = token;
  }

  useUserID(iUserId) {
    this.isLoggedIn = true;
    this.iUserId = iUserId;
  }

  useUserType(iUserType) {
    this.isLoggedIn = true;
    this.iUserType = iUserType;
  }

  useClientID(iClient) {
    this.isLoggedIn = true;
    this.iClient = iClient;
  }

  usePBMmodeID(iPbmMode) {
    this.isLoggedIn = true;
    this.iPbmMode = iPbmMode;
  }

  useallowPBM(bAllowPbm) {
    this.isLoggedIn = true;
    this.bAllowPbm= bAllowPbm;
  }

  useallowSHOP(bAllowSHOP) {
    this.isLoggedIn = true;
    this.bAllowShop= bAllowSHOP;
  }

  useLogedIn(bLoggedIn) {
    this.isLoggedIn = true;
    this.bLoggedIn= bLoggedIn;
  }

  userFullname(fullname){
    this.isLoggedIn = true;
    this.fullname = fullname;
    console.log(fullname)
  }

  loadUserCredentials() {
    let token = window.localStorage.getItem('Authtoken') || this.storage.get('Authtoken');
    this.useCredentials(token);
  }

  destroyUserCredentials() {
      this.isLoggedIn = false;
      this.AuthToken = null;
      window.localStorage.clear();
  }

  createAuthorization(headers: Headers) {
    headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  }

  async PBMloginNative() {
    // let loading =  this.loadingCtrl.create();
    let sha = CryptoJS.SHA512(this.AuthToken).toString(CryptoJS.enc.Base64);
    // let encode = CryptoJS.enc.Base64.stringify(sha)
    let encodedSource1 = sha.replace(/=+$/, '');
    let encodedSource2 = encodedSource1.replace(/\+/g, '-');
    let encodedSource3 = encodedSource2.replace(/\//g, '_');
    let part = encodedSource3.slice(4, 25);
    console.log('hash', sha);
    console.log('hash', encodedSource3);
    let string = JSON.stringify(this.AuthToken)
    
    let headers = {
      "Content-Type": "application/json",
      "UserToken": `${this.AuthToken}`
    };
    console.log(headers);
    console.log(string)
    console.log('pbmlogin', this.AuthToken)
    this.nativeHttp.get('https://demorest.msgsoftware.nl/Authorisation/'+part+'?SetPBM=On', {}, headers)
              .then((data) => {
      let parsed = JSON.parse(data.data);
      this.storePBMUserCredentials(parsed.stAuthResp.sSessionID);
        this.storeUserID(parsed.stAuthResp.iUserId);
        this.storeClientID(parsed.stAuthResp.iClient);
        this.storePBM(parsed.stAuthResp.iPbmMode);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        this.userFullname(parsed.stAuthResp.sFullName);
              })
              .catch((error) => {
                  console.log(error);
              });
    // let nativeCall = this.nativeHttp.post('https://demorest.msgsoftware.nl/Authentication/'+part+'?SetPBM=On',{}, headers)
    // console.log('this is native');
    // from(nativeCall).pipe(
    //   finalize(() => console.log('ok'))
    // )
    // .subscribe(data => {

    //   let parsed = JSON.parse(data.data);
    //   // this.auth = parsed.stAuthResp;
    //   // console.log(this.promo);
    //   console.log(data);
    //   if(parsed.stAuthResp){
    //     this.storeUserCredentials(parsed.stAuthResp.sSessionID);
    //     this.storeUserID(parsed.stAuthResp.iUserId);
    //     this.storePBM(parsed.stAuthResp.iPbmMode);
    //     console.log(parsed.stAuthResp.iPbmMode);
    //     console.log(parsed);
    //     this.userFullname(parsed.stAuthResp.sFullName);
    //     // resolve(true);
    //   }
    // },
    //  err => {
    //   console.log('native error : ', err);
    // });
  }
  async Clientlogin(loginparams){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      headers.append('content-type','application/json');
      headers.append('UserToken', this.AuthToken)
      console.log('pbmlogin', this.AuthToken)
      // var b64 = CryptoJS.AES.encrypt(plainText, SECRET).toString();
      let sha = CryptoJS.SHA512(this.AuthToken).toString(CryptoJS.enc.Base64);
      // let encode = CryptoJS.enc.Base64.stringify(sha)
      let encodedSource1 = sha.replace(/=+$/, '');
      let encodedSource2 = encodedSource1.replace(/\+/g, '-');
      let encodedSource3 = encodedSource2.replace(/\//g, '_');
      let part = encodedSource3.slice(4, 25);
      console.log('hash', sha);
      console.log('hash', encodedSource3);
      // console.log('hash', encode);
      let url = 'https://demorest.msgsoftware.nl/Authorisation/'+part; 
      console.log(url)
      console.log('clientservice', JSON.stringify(loginparams))
      let user = this.http.put(url, JSON.stringify(loginparams), options)
      .subscribe( data => {
        console.log(data);
          if(data.json().stAuthResp){
              this.storePBMUserCredentials(data.json().stAuthResp.sSessionID);
              this.storeUserID(data.json().stAuthResp.iUserId);
              this.storeClientID(data.json().stAuthResp.iClient);
              this.storeUserType(data.json().stAuthResp.iUserType);
              this.storage.set('userToken', this.AuthToken);
              console.log(this.AuthToken);
              this.storePBM(data.json().stAuthResp.iPbmMode);
              console.log(data.json().stAuthResp.iPbmMode);
              console.log(data.json().stAuthResp.iClient);
              this.userFullname(data.json().stAuthResp.sFullName);
              resolve(true);
          }
          else
              resolve(false);
      });
    });
  }
  async ClientloginNative(loginparams) {
    // let loading =  this.loadingCtrl.create();
    let sha = CryptoJS.SHA512(this.AuthToken).toString(CryptoJS.enc.Base64);
    // let encode = CryptoJS.enc.Base64.stringify(sha)
    let encodedSource1 = sha.replace(/=+$/, '');
    let encodedSource2 = encodedSource1.replace(/\+/g, '-');
    let encodedSource3 = encodedSource2.replace(/\//g, '_');
    let part = encodedSource3.slice(4, 25);
    console.log('hash', sha);
    console.log('hash', encodedSource3);
    let string = JSON.stringify(this.AuthToken)
    
    let headers = {
      "Content-Type": "application/json",
      "UserToken": `${this.AuthToken}`
    };
    console.log(headers);
    console.log(string)
    console.log('pbmlogin', this.AuthToken)
    this.nativeHttp.put('https://demorest.msgsoftware.nl/Authorisation/'+part, loginparams, headers)
              .then((data) => {
      let parsed = JSON.parse(data.data);
      this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        this.storeUserID(parsed.stAuthResp.iUserId);
        this.storeClientID(parsed.stAuthResp.iClient);
        this.storePBM(parsed.stAuthResp.iPbmMode);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        this.userFullname(parsed.stAuthResp.sFullName);
              })
              .catch((error) => {
                  console.log(error);
              });
    // let nativeCall = this.nativeHttp.post('https://demorest.msgsoftware.nl/Authentication/'+part+'?SetPBM=On',{}, headers)
    // console.log('this is native');
    // from(nativeCall).pipe(
    //   finalize(() => console.log('ok'))
    // )
    // .subscribe(data => {

    //   let parsed = JSON.parse(data.data);
    //   // this.auth = parsed.stAuthResp;
    //   // console.log(this.promo);
    //   console.log(data);
    //   if(parsed.stAuthResp){
    //     this.storeUserCredentials(parsed.stAuthResp.sSessionID);
    //     this.storeUserID(parsed.stAuthResp.iUserId);
    //     this.storePBM(parsed.stAuthResp.iPbmMode);
    //     console.log(parsed.stAuthResp.iPbmMode);
    //     console.log(parsed);
    //     this.userFullname(parsed.stAuthResp.sFullName);
    //     // resolve(true);
    //   }
    // },
    //  err => {
    //   console.log('native error : ', err);
    // });
  }

}