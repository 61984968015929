import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UploadBoxComponent } from './upload-box/upload-box.component';
import { CartTotalComponent } from './cart-total/cart-total.component';
import { PbmcartTotalComponent } from './pbmcart-total/pbmcart-total.component';
import{ PbmcartOrdertotalComponent} from './pbmcart-ordertotal/pbmcart-ordertotal.component'
import{ CartOrdertotalComponent} from './cart-ordertotal/cart-ordertotal.component'

@NgModule({
	declarations: [
		UploadBoxComponent, CartTotalComponent, PbmcartTotalComponent, CartOrdertotalComponent, PbmcartOrdertotalComponent
	],
	imports: [
		CommonModule,
		IonicModule,
	],
	exports: [
		UploadBoxComponent, CartTotalComponent, PbmcartTotalComponent, CartOrdertotalComponent, PbmcartOrdertotalComponent
	]
})
export class ComponentsModule {}
