import { Injectable } from "@angular/core";
import { CacheService } from 'ionic-cache';
import { Events,  LoadingController, Platform } from "@ionic/angular";
import { Storage } from '@ionic/storage';
import { Observable} from 'rxjs';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { File } from '@ionic-native/file/ngx';
import { from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class AuthProvider {
  userKey = 'my-user-group';
  user: any;
  userid: any;
  PBMuserKey = 'my-user-group';
  PBMuser: any;
  isLoggedIn = false;
  AuthToken: string;
  PBMAuthToken: string;
  UserID: any;
  iPbmMode: any;
  fullname: string;
  constructor(private nativeHttp: HTTP, public plt: Platform, private loadingCtrl: LoadingController, public events: Events,private http: Http, private cache: CacheService,public storage: Storage,) {
    this.isLoggedIn = false;
    this.AuthToken = null;
  }

  async login(loginParams){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
      console.log(JSON.stringify(loginParams));
      let user = this.http.post(url, JSON.stringify(loginParams), options)
      .subscribe( data => {
          if(data.json().stAuthResp){
              this.storeUserCredentials(data.json().stAuthResp.sSessionID);
              this.storeUserID(data.json().stAuthResp.iUserId);
              this.storage.set('userToken', this.AuthToken);
              this.storePBM(data.json().stAuthResp.iPbmMode);
              console.log(data.json().stAuthResp.iPbmMode);
              console.log(data.json());
              this.userFullname(data.json().stAuthResp.sFullName);
              resolve(true);
          }
          else
              resolve(false);
      });
    });
  }

  async PBMlogin(loginParams){
    return new Promise((resolve, reject) => {
      var headers = new Headers();
      let options = new RequestOptions({ headers: headers, withCredentials: true });
      headers.append('Access-Control-Allow-Origin' , '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      headers.append('Accept','application/json');
      headers.append('content-type','application/json');
      let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
      console.log(JSON.stringify(loginParams));
      let user = this.http.post(url, JSON.stringify(loginParams), options)
      .subscribe( data => {
          if(data.json().stAuthResp){
              this.storeUserCredentials(data.json().stAuthResp.sSessionID);
              this.storeUserID(data.json().stAuthResp.iUserId);
              this.storage.set('userToken', this.AuthToken);
              this.storePBM(data.json().stAuthResp.iPbmMode);
              console.log(data.json().stAuthResp.iPbmMode);
              console.log(data.json());
              this.userFullname(data.json().stAuthResp.sFullName);
              resolve(true);
          }
          else
              resolve(false);
      });
    });
  }
 
   async loginNative(loginParams) {
    let loading =  this.loadingCtrl.create();
     
    let headers = {
      'Content-Type': 'application/json'
    };
    let nativeCall = this.nativeHttp.post('https://demorest.msgsoftware.nl/Authentication', JSON.stringify(loginParams), headers)
    console.log('this is native');
    from(nativeCall).pipe(
      finalize(() => console.log('ok'))
    )
    .subscribe(data => {

      let parsed = JSON.parse(data.data);
      console.log(data);
      if(parsed.stAuthResp){
        this.storeUserCredentials(parsed.stAuthResp.sSessionID);
        this.storeUserID(parsed.stAuthResp.iUserId);
        this.storePBM(parsed.stAuthResp.iPbmMode);
        console.log(parsed.stAuthResp.iPbmMode);
        console.log(parsed);
        this.userFullname(parsed.stAuthResp.sFullName);
      }
    },
     err => {
      console.log('JS Call error: ', err);
    });
  }

  async loginSwitch(loginParams) {
    if (this.plt.is('cordova')) {
      this.loginNative(loginParams);
    } else {
      this.login(loginParams);
    }
  }

  // PBMlogin(loginParams){
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin' , '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept','application/json');
  //     headers.append('content-type','application/json');
  //     let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
  //     console.log(JSON.stringify(loginParams));
  //     let PBMuser = this.http.post(url, JSON.stringify(loginParams), options)
  //     .subscribe( data => {
  //         if(data.json().stAuthResp){
  //             this.storePBMUserCredentials(data.json().stAuthResp.sSessionID);
  //             this.storeUserID(data.json().stAuthResp.iUserId);
  //             this.storePBM(data.json().stAuthResp.iPbmMode);
  //             // console.log(data.json().stAuthResp);
  //             // console.log(data.json().stAuthResp.sSessionID);
  //             this.userFullname(data.json().stAuthResp.sFullName);
  //             resolve(true);
  //         }
  //         else
  //             resolve(false);
  //     });
  //   });
  // }
    storeUserCredentials(token) {
      window.localStorage.setItem('token', token);
      this.storage.set('token', token);
      this.useCredentials(token);
    }

    storePBMUserCredentials(token) {
      window.localStorage.setItem('token', token);
      this.storage.set('token', token);
      this.useCredentials(token);
    }

    storeUserID(UserID) {
      window.localStorage.setItem('token', UserID);
      this.storage.set('token', UserID);
      this.useUserID(UserID);
    }

    storePBM(iPbmMode) {
      window.localStorage.setItem('token', iPbmMode);
      this.storage.set('token', iPbmMode);
      this.usePBMmodeID(iPbmMode);
    }
  
    useCredentials(token) {
      this.isLoggedIn = true;
      this.AuthToken = token;
    }

    useUserID(UserID) {
      this.isLoggedIn = true;
      this.UserID = UserID;
    }

    usePBMmodeID(iPbmMode) {
      this.isLoggedIn = true;
      this.iPbmMode = iPbmMode;
    }

    userFullname(fullname){
      this.isLoggedIn = true;
      this.fullname = fullname;
      console.log(fullname)
    }
  
    loadUserCredentials() {
      let token = window.localStorage.getItem('token') || this.storage.get('token');
      this.useCredentials(token);
    }

    destroyUserCredentials() {
        this.isLoggedIn = false;
        this.AuthToken = null;
        window.localStorage.clear();
    }
  
    createAuthorization(headers: Headers) {
      headers.append('UserToken', window.localStorage.getItem('token'));
    }

  // login(loginParams){
  //     var promise = new Promise((resolve, reject) => {
  //     let headers = new Headers();
  //     headers.append('Access-Control-Allow-Origin' , '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept','application/json');
  //     headers.append('content-type','application/json');
  //     let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
  //     this.http.post(url, JSON.stringify(loginParams), {headers: headers}).then(() => {
  //             resolve(true);
  //           }).catch((err) => {
  //             reject(err);
  //            })
  //         })
      
  //         return promise;

  //   });
  // }

  // userdetails(userid){
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin' , '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept','application/json');
  //     headers.append('content-type','application/json');
  //     let url = 'https://demorest.msgsoftware.nl/Authorisation'; 
  //     console.log(JSON.stringify(userid));
  //     let user = this.http.post(url, JSON.stringify(userid), options)
  //     .subscribe( data => {
  //         if(data.json().stAuthResp){
  //             this.storeUserCredentials(data.json().stAuthResp.sSessionID);
  //             console.log(data.json().stAuthResp)
  //             this.userFullname(data.json().stAuthResp.sFullName);
  //             resolve(true);
  //         }
  //         else
  //             resolve(false);
  //     });
  //   });
  // }

  // registerUser(userObj: any) {
  //   var promise = new Promise((resolve, reject) => {
  //     firebase .auth().createUserWithEmailAndPassword(userObj.email, userObj.password)
  //       .then(() => {
  //         this.firedata.child(firebase.auth().currentUser.uid).set({
  //           name:userObj.name,
  //           address:userObj.address,
  //           email:userObj.email
  //         }).then(()=>{
  //           resolve({ success: true });
  //         }).catch((err)=>{
  //           reject(err);
  //         })
  //        // resolve(true);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   })
  //   return promise;
  // }

  // getuserdetails() {
  //   var promise = new Promise((resolve, reject) => {
  //   this.firedata.child(firebase.auth().currentUser.uid).once('value', (snapshot) => {
  //     resolve(snapshot.val());
  //   }).catch((err) => {
  //     reject(err);
  //     })
  //   })
  //   return promise;
  // }
}
