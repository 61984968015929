import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { ProductsProvider } from '../app/providers/products/products';
import { CategoryProvider } from '../app/providers/category/category';
import { CartProvider } from '../app/providers/cart/cart';
import { AuthProvider } from '../app/providers/auth/auth';
import { OrderProvider } from '../app/providers/order/order';
import { LastseeProvider } from '../app/providers/lastsee/lastsee';
import { SearchProvider } from '../app/providers/search/search';
import { ApiProvider } from '../app/providers/api/api';
import { CompareProvider } from '../app/providers/compare/compare';
import { DataFinder } from '../app/providers/datafinder';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ModalPageModule } from './pages/modal/modal.module';
import { VariantmodalPageModule } from './pages/variantmodal/variantmodal.module';
import { SettingsComponent } from './components/settings/settings.component';
import { PbmpointsComponent } from './components/pbmpoints/pbmpoints.component';
import { CartTotalComponent } from './components/cart-total/cart-total.component';
import 'hammerjs';
import { HttpModule } from '@angular/http';
// import { HttpClientModule } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';
import { CacheModule } from 'ionic-cache/dist';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { MomentModule } from 'angular2-moment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ThemeableBrowser } from '@ionic-native/themeable-browser/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';

// import { LoginPage } from './pages/login/login';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
library.add();
@NgModule({
  declarations: [AppComponent, SettingsComponent, PbmpointsComponent],
  entryComponents: [SettingsComponent, PbmpointsComponent],
  imports: [BrowserModule, HttpModule,CacheModule.forRoot(),IonicModule.forRoot(), AppRoutingModule,IonicStorageModule.forRoot(),
    HttpClientModule,
    FontAwesomeModule,
    ModalPageModule,
    VariantmodalPageModule,
    BrowserAnimationsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LazyLoadImageModule,
    ImgFallbackModule,
    NgxSkeletonLoaderModule,
    MomentModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
    schemas: [ NO_ERRORS_SCHEMA ],
  providers: [
    HTTP,
    File,
    StatusBar,
    BarcodeScanner,
    Network,
    InAppBrowser,
    ThemeableBrowser,
    CallNumber,
    AppVersion,
    EmailComposer,
    // FingerprintAIO,
    SplashScreen,
    // ScreenOrientation,
    ProductsProvider,
    CategoryProvider,
    // NativePageTransitions,
    CartProvider,
    AuthProvider,
    OrderProvider,
    // Push,
    // GoogleMaps,
    SearchProvider,
    ApiProvider,
    SocialSharing,
    CompareProvider,
    // File,
    // Network,
    // OneSignal,
    DataFinder,
    LastseeProvider,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
