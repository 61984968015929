import { Component, Injector, OnInit, ChangeDetectorRef } from '@angular/core';

import { Platform, ToastController, AlertController, LoadingController, Events, Config} from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { User } from './services/user';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import nl from '@angular/common/locales/nl';
// import { Category } from './services/category';
// import { Item } from './services/item';
// import { Card } from './services/card';
import { Subject, Observable, merge } from 'rxjs';
import { Preference } from './services/preference';
import { CartService } from './services/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from './services/local-storage';
import { OfflineManagerService } from './services/offline-manager.service';
import { NetworkService, ConnectionStatus } from './services/network.service';
import { CategoryService } from './services/category.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AuthService } from './services/auth.service';
// import { Installation } from './services/installation';
// import { WindowRef } from './services/window-ref';
// import { HeaderColor } from '@ionic-native/header-color/ngx';
// import * as Parse from 'parse';
// import { Slide } from './services/slide';
import { Router } from '@angular/router';
// import { StripeService } from 'ngx-stripe';
// import { Stripe } from '@ionic-native/stripe/ngx';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  // public user: User;

  private loader = null;
  private objWindow = null;
  public cartCount:any;
  public skeletonArray = Array(4);
  number:any;
  list_ = true;
  grid_ = false;
  isLoaded = false;
  cartItems: any;
  categories: any;
  subcategories:any;
  totalAmount: number = 0;
  isCartItemLoaded: boolean = false;
  protected contentLoaded: Subject<any>;
  constructor(
    injector: Injector,
    private platform: Platform,
    private router: Router,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private preference: Preference,
    private loadingCtrl: LoadingController,
    private cartService: CartService,
    private statusBar: StatusBar,
    private changeDetector: ChangeDetectorRef,
    public api2: CategoryService,
    private offlineManager: OfflineManagerService,
    private networkService: NetworkService,
    // private headerColor: HeaderColor,
    private translate: TranslateService,
    private events: Events,
    private localStorage: LocalStorage,
    private plt: Platform,
    private appversion : AppVersion,
    public authService : AuthService,
    // public splashscreen : SplashScreen
    // private userService: User,
    // private windowRef: WindowRef,
    // private installationService: Installation,
    // private stripeService: StripeService,
    // private stripe: Stripe,
    ) {
      console.log('App version MSG', this.appversion.getVersionNumber());
    this.initializeApp();
    this.contentLoaded = new Subject();
  }

  async initializeApp() {
    // this.objWindow = this.windowRef.nativeWindow;

    // this.setupParse();

    this.setupFacebookSdk();

    // this.user = User.getCurrent();

    this.setupLanguage();
    this.setupEvents();
    this.setupDesktopAnimations();
    this.Guestlogin();

    this.platform.ready().then(() => {
      setTimeout(() => {
        SplashScreen.hide();
      }, 1000);
      this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
        if (status == ConnectionStatus.Online) {
          this.offlineManager.checkForEvents().subscribe();
        }
      });
    });
    
    this.setupStatusBar();
    setTimeout(() => {
      this.isLoaded = true;
    }, 2000);
    this.loadData();
    this.getCategories();
    // this.setupPush();
    this.setupStripe();
    if (this.platform.is('android')) this.setupHeaderColor();
  }

  ngOnInit() {
    registerLocaleData( nl );
    this.setupObservable();
  }
  setupObservable() {

      this.contentLoaded
 
  }

  Guestlogin() {

    // let loader = this.loadingCtrl.create({
    //   content: 'Authenticating..'
    // });
    // loader.present();
    let me=this;
    let loginParams = null
    // console.log(this.email);
    console.log('empty2', loginParams);
  if (this.plt.is('cordova')) {
    this.authService.guestNative(loginParams).then(res => {
  
        console.log('test login page', JSON.stringify(res));
        // this.navigateTo('/1//home');
        this.authService.guestlogin(JSON.stringify(res));
        console.log
        // this.authService.guestlogin(JSON.parse(res.data));
  
    });
  
  }else{
    this.authService.Guestlogin().then(res => {
      console.log(res)
        // this.navigateTo('/1//home');
    });
  }
    console.log(this.authService.iPbmMode);
  
  //  }
  
  }


  loadData(event: any = {}) {

    try {


      this.loadCart();
      // this.showContentView();
      this.getCategories();
      this.getSubCategories(this.subcategories);
      this.onContentLoaded();
      // this.onRefreshComplete(this.cartItems);
      
    } catch (error) {
      // this.showContentView();
      this.translate.get('ERROR_NETWORK').subscribe(str => this.showToast(str));
    }
  }

  onContentLoaded() {
    setTimeout(() => {
      this.contentLoaded.next();
    }, 400);
  }

  // setupParse() {
  //   (Parse as any).serverURL = environment.serverUrl;
  //   Parse.initialize(environment.appId);

  //   Slide.getInstance();
  //   Category.getInstance();
  //   Item.getInstance();
  //   Card.getInstance();

  //   this.loadCart();
  // }

  setupFacebookSdk() {
    if (!this.platform.is('hybrid')) {
      // Load the Facebook API asynchronous when the window starts loading

      // this.objWindow.fbAsyncInit = function() {
      //   Parse.FacebookUtils.init({
      //     // appId: environment.fbId,
      //     cookie: true,
      //     xfbml: true, 
      //     version: 'v1.0'
      //   });
      // };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/all.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }

//   onRightClick() {
//     return false;
// }

  setupStripe() {
    // if (this.platform.is('hybrid')) {
    //   this.stripe.setPublishableKey(environment.stripePublicKey);
    // } else {
    //   this.stripeService.setKey(environment.stripePublicKey);
    // }

  }

  setupHeaderColor() {
    // this.headerColor.tint(environment.androidHeaderColor);
  }

  setupDesktopAnimations() {
    if (this.platform.is('desktop')) {
      const config = new Config;
      config.set('rippleEffect', false);
      config.set('animated', false);
    }
  }

  async setupLanguage() {
    this.translate.setDefaultLang(environment.defaultLang);

    try {

      let lang = await this.localStorage.getLang();

      if (lang === 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }

      this.localStorage.setLang(lang);
      this.translate.use(lang);
      this.preference.lang = lang; 

    } catch (error) {
      console.warn(error);
    }
  }

  setupEvents() {
   let cart = this.cartService.getCartItems();
    this.events.subscribe('cart:updated', () => {
      this.updateCartCount(cart);
    });
  }

  //   this.events.subscribe('user:login', user => {
  //     this.user = user;
  //     this.updateInstallation();
  //     this.loadCart();
  //   });

  //   this.events.subscribe('user:logout', (ev) => {
  //     this.logout(ev);
  //   });
  // }

  setupStatusBar() {
    if (this.platform.is('ios')) {
      this.statusBar.overlaysWebView(true);
      this.statusBar.styleDefault();
    } else {
      // this.statusBar.backgroundColorByHexString(environment.androidHeaderColor);
    }
  }

  async loadCart() {

 

    this.cartService.getCartItems()
      .then(val => {
        this.cartItems = val;
        console.log('app cart', this.cartItems)
        if (this.cartItems.length > 0) {
          this.cartItems.forEach((v, indx) => {
            this.totalAmount += parseFloat(v.totalPrice);
          });
          // this.isEmptyCart = false;
          // loader.present();
        }

        this.isCartItemLoaded = true;
        // loader.dismiss();
    })

        let cart = await this.cartService.getCartItems();
        cart = cart

        this.changeDetector.detectChanges();
        // this.updateCartCount(cart);


  }

  async getCategories() {
    // const loading = await this.loadingController.create({
    //   message: 'Please wait...',
    // });
    // await loading.present();
    if (this.plt.is('cordova')) {
      this.api2.getCategoriesNative()
      .then(data => {
        let parsed = JSON.parse(data.data);
        this.categories = parsed.stNavMenu.arrMenuGroup;
        console.log('categories', data);
        // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
    } else {
      this.api2.getCategories()
      .subscribe(res => {
        console.log('categories', res);
        this.categories = res['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
    }
  }

  async getSubCategories(category:any) {
    if (this.plt.is('cordova')) {
      this.api2.getSubCategoriesNative(category)
      .then(data => {
        let parsed = JSON.parse(data.data);
        this.subcategories = parsed.stNavMenu.arrMenuGroup;
        console.log('categories', data);
        // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
    } else {
    this.api2.getSubCategories(category)
      .subscribe(res => {
        this.list_ = true;
        this.grid_ = false;
        console.log('categories', res);
        this.subcategories = res['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
    }
  }

  updateCartCount(cart:any) {
    cart =  this.cartService.getCartItems();
    this.cartCount = cart.length
    this.cartCount = this.cartCount
    console.log('cart',

this.cartCount
    
    ) 

  }

  // async setupPush() {

  //   if (this.objWindow.ParsePushPlugin) {

  //     this.objWindow.ParsePushPlugin.resetBadge();

  //     this.platform.resume.subscribe(() => {      
  //       this.objWindow.ParsePushPlugin.resetBadge();
  //     });

  //     this.objWindow.ParsePushPlugin.on('receivePN', (pn) => {
  //       console.log('Push notification:' + JSON.stringify(pn));
  //       this.objWindow.ParsePushPlugin.resetBadge();
  //     });

  //     this.objWindow.ParsePushPlugin.on('receivePN:news', (pn) => {
  //       console.log('News Notification:' + JSON.stringify(pn));
  //       this.showNotification(pn);
  //       this.objWindow.ParsePushPlugin.resetBadge();
  //     });

  //     this.objWindow.ParsePushPlugin.on('receivePN:order', (pn) => {
  //       console.log('Order Notification:' + JSON.stringify(pn));
  //       this.showNotification(pn);
  //       this.objWindow.ParsePushPlugin.resetBadge();
  //     });

  //     this.objWindow.ParsePushPlugin.on('openPN', (pn) => {
  //       console.log('Notification:' + JSON.stringify(pn));

  //       this.objWindow.ParsePushPlugin.resetBadge();

  //       if (pn.event === 'order') {
  //         this.showNotification(pn);
  //       }

  //       if (pn.event === 'post') {
  //         this.showNotification(pn);
  //       }

  //     });

  //     this.updateInstallation();
  //   }
  // }

  async updateInstallation() {

    try {

      if (this.objWindow.ParsePushPlugin) {

        let payload: any = {
          user: null
        };

        // const id = await this.installationService.getId();

        // const obj = await this.installationService.getOne(id);

        // if (obj) {
        //   payload.isPushEnabled = obj.isPushEnabled;
        //   this.localStorage.setIsPushEnabled(obj.isPushEnabled);
        //   this.preference.isPushEnabled = obj.isPushEnabled; 
        // }

        // if (this.user) {
        //   payload.user = this.user.toPointer();
        // }

        // const res = await this.installationService.save(id, payload);
        // console.log('Installation updated', res);
      }

    } catch (error) {
      console.warn(error);
    }
  }

  async showNotification(notification: any) {
    const str = await this.translate.get(['NOTIFICATION', 'OK']).toPromise();
    this.showAlert(str.NOTIFICATION, notification.alert, str.OK);
  }

  async showAlert(title: string = '', message: string = '', okText: string = 'OK') {
    
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [okText],
    });

    return await alert.present();
  }

  showConfirm(message: string): Promise<any> {

    return new Promise(async (resolve, reject) => {

      const str = await this.translate.get(['OK', 'CANCEL']).toPromise();

      const confirm = await this.alertCtrl.create({
        header: '',
        message: message,
        buttons: [{
          text: str.CANCEL,
          role: 'cancel',
          handler: () => reject(false),
        }, {
          text: str.OK,
          handler: () => resolve(true)
        }]
      });

      confirm.present();
      
    });
  }

  async showLoadingView() {

    const str = await this.translate.get('LOADING').toPromise();

    this.loader = await this.loadingCtrl.create({
      message: str
    });
    
    return await this.loader.present();
  }

  dismissLoadingView() {
    if (this.loader) {
      this.loader.dismiss().catch((e: any) => console.log('ERROR CATCH: LoadingController dismiss', e));
    }
  }

  async showToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: 'bottom'
    });

    return await toast.present();
  }

  goTo(page: string) {
    this.router.navigate([page]);
  }

  async logout(ev: any = {}) {

    try {

      if (!ev.silent) {
        let str = await this.translate.get('LOGOUT_CONFIRMATION').toPromise();
        await this.showConfirm(str);
      }

      await this.showLoadingView();
      // await this.userService.logout();
      this.events.publish('user:loggedOut');
      // this.user = null;
      this.goTo('/');
      // this.updateCartCount(new Cart);
      this.dismissLoadingView();
      this.updateInstallation();
      this.translate.get('LOGGED_OUT').subscribe(str => this.showToast(str));

    } catch (err) {
      this.dismissLoadingView();
    }
  }
}
